export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSIPAddress: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export type AsCoords = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type AsPdfData = {
  pdfTextCoords: Array<InputMaybe<Array<InputMaybe<AsTextCoords>>>>;
  pdfType: Scalars['String']['input'];
};

export type AsTextCoords = {
  coords: AsCoords;
  text: Scalars['String']['input'];
};

export type Accountant = {
  __typename?: 'Accountant';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idNumber: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
  signatureName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AccountantSignTaxAuthorityAndBituahLeumiDocumentsInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type AccountantSignTaxAuthorityAndBituahLeumiDocumentsOutput = {
  __typename?: 'AccountantSignTaxAuthorityAndBituahLeumiDocumentsOutput';
  data: Scalars['Boolean']['output'];
};

export type AccountantSignVatBusinessCreationDocumentInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type AccountantSignVatBusinessCreationDocumentOutput = {
  __typename?: 'AccountantSignVatBusinessCreationDocumentOutput';
  data: Scalars['Boolean']['output'];
};

export enum ActivitiesTabs {
  ALL = 'ALL',
  EXPENSES = 'EXPENSES',
  INCOMES = 'INCOMES'
}

export type AddCreditsInput = {
  credits: Scalars['Float']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  type: CreditTypeEnum;
  userId: Scalars['ID']['input'];
};

export type AddCreditsOutput = {
  __typename?: 'AddCreditsOutput';
  creditType: CreditTypeEnum;
  credits: Scalars['Float']['output'];
  userId: Scalars['ID']['output'];
};

export type AddDiscountPhoneNumberInput = {
  mobile: Scalars['String']['input'];
  planType?: InputMaybe<PlanType>;
  planTypeFree?: InputMaybe<PlanType>;
};

export type AddDiscountPhoneNumberOutput = {
  __typename?: 'AddDiscountPhoneNumberOutput';
  mobile: Scalars['String']['output'];
  planType?: Maybe<PlanType>;
  planTypeFree?: Maybe<PlanType>;
};

export type AddNewStaffInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  idNumber: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StaffTypes>;
};

export type AddNewStaffOutput = {
  __typename?: 'AddNewStaffOutput';
  data: Scalars['Boolean']['output'];
};

export type ApproveBusinessExpenseInput = {
  businessId: Scalars['ID']['input'];
  checkDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  expenseId: Scalars['ID']['input'];
};

export type ApproveBusinessExpenseOutput = {
  __typename?: 'ApproveBusinessExpenseOutput';
  expenseStatus: BusinessExpenseStatus;
  id: Scalars['ID']['output'];
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
};

export type ApproveBusinessInput = {
  advancePercentage?: InputMaybe<Scalars['Float']['input']>;
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type ApproveBusinessOutput = {
  __typename?: 'ApproveBusinessOutput';
  data: Scalars['Boolean']['output'];
};

export type ApproveRepresentationInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type ApproveRepresentationOutput = {
  __typename?: 'ApproveRepresentationOutput';
  data: Scalars['Boolean']['output'];
};

export type ApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type ApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsOutput = {
  __typename?: 'ApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsOutput';
  data: Scalars['Boolean']['output'];
};

export type ApproveSignaturesOnVatBusinessCreationDocumentInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type ApproveSignaturesOnVatBusinessCreationDocumentOutput = {
  __typename?: 'ApproveSignaturesOnVatBusinessCreationDocumentOutput';
  data: Scalars['Boolean']['output'];
};

export type AutoSignInput = {
  base64Image: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
  pdfsData: Array<AsPdfData>;
  stage: Scalars['String']['input'];
};

export type AutoSignOutput = {
  __typename?: 'AutoSignOutput';
  data: Array<PdfDataOutput>;
};

export enum AverageWorkingHoursOptions {
  HOURS_1_11 = 'HOURS_1_11',
  HOURS_12_19 = 'HOURS_12_19',
  HOURS_20PLUS = 'HOURS_20PLUS'
}

export type BankDetails = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankBranch?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Translations>;
};

export enum BankDocumentType {
  ACCOUNT_OWNERSHIP = 'ACCOUNT_OWNERSHIP',
  CANCELLED_CHECK = 'CANCELLED_CHECK'
}

export enum BillingsChartOrder {
  MAX = 'MAX',
  ONE_YEAR = 'ONE_YEAR',
  YTD = 'YTD'
}

export type Business = {
  __typename?: 'Business';
  accountantId?: Maybe<Scalars['ID']['output']>;
  accountantRepresentation?: Maybe<BusinessAccountantRepresentationType>;
  advanceTaxRate?: Maybe<Scalars['Float']['output']>;
  approvalStatus?: Maybe<BusinessApprovalStatus>;
  approvedAt?: Maybe<Scalars['String']['output']>;
  bankDetails?: Maybe<Scalars['String']['output']>;
  billings?: Maybe<Array<BusinessBilling>>;
  bituahLeumiPayment: Scalars['Float']['output'];
  businessIndustryId?: Maybe<Scalars['ID']['output']>;
  businessName: Scalars['String']['output'];
  businessNameEn?: Maybe<Scalars['String']['output']>;
  businessSecondaryIndustryId?: Maybe<Scalars['ID']['output']>;
  businessStatus: BusinessStatus;
  businessType: BusinessType;
  canBeBlocked?: Maybe<Scalars['Boolean']['output']>;
  cancelledAt?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  cityEn?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creditCardBuyerKey?: Maybe<Scalars['String']['output']>;
  creditCardExpiration?: Maybe<Scalars['String']['output']>;
  creditCardMask?: Maybe<Scalars['String']['output']>;
  customers?: Maybe<Array<BusinessCustomer>>;
  exemption?: Maybe<BusinessExemption>;
  expectedMonthlyIncome?: Maybe<Scalars['Float']['output']>;
  expenses?: Maybe<Array<BusinessExpense>>;
  id: Scalars['ID']['output'];
  incomeDocumentComments?: Maybe<Scalars['String']['output']>;
  incomes?: Maybe<Array<BusinessIncome>>;
  industry?: Maybe<BusinessIndustry>;
  isEmployee: Scalars['Boolean']['output'];
  isImportDocuments?: Maybe<Scalars['Boolean']['output']>;
  isNewBusiness?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['Boolean']['output']>;
  missingDocuments?: Maybe<MissingDocument>;
  notifications?: Maybe<ModelBusinessNotificationConnection>;
  partnerRegistrationNumber?: Maybe<Scalars['String']['output']>;
  paymentIdNumber: Scalars['String']['output'];
  referralId?: Maybe<Scalars['String']['output']>;
  registrationNumber: Scalars['String']['output'];
  reportType?: Maybe<BusinessReportType>;
  reports?: Maybe<Array<BusinessReport>>;
  representationRequestNumber?: Maybe<Scalars['String']['output']>;
  secondaryIndustry?: Maybe<BusinessIndustry>;
  streetAddress: Scalars['String']['output'];
  taoaAccessTokenExpiresTime?: Maybe<Scalars['String']['output']>;
  taoaLastAuthenticationTime?: Maybe<Scalars['String']['output']>;
  taoaRefreshTokenExpiresTime?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};


export type BusinessBillingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export enum BusinessAccountantRepresentationType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export type BusinessActivity = {
  __typename?: 'BusinessActivity';
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currencyRate?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceIncludingVat?: Maybe<Scalars['Float']['output']>;
  reportEndDate?: Maybe<Scalars['String']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportStartDate?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<BusinessReportType>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum BusinessApprovalStatus {
  BUSINESS_APPROVED = 'BUSINESS_APPROVED',
  DIN_VE_HESHBON_COMPLETED = 'DIN_VE_HESHBON_COMPLETED',
  DIN_VE_HESHBON_NEEDED = 'DIN_VE_HESHBON_NEEDED',
  MISSING_DOCUMENTS_BUSINESS_NEEDED = 'MISSING_DOCUMENTS_BUSINESS_NEEDED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  REPRESENTATION_APPROVED = 'REPRESENTATION_APPROVED',
  TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_ACCOUNTANT_SIGNED = 'TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_ACCOUNTANT_SIGNED',
  TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_BUSINESS_SIGNED = 'TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_BUSINESS_SIGNED',
  TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_UPLOAD_UNSIGNED = 'TAX_AUTHORITY_AND_BITUAH_LEUMI_DOCUMENTS_UPLOAD_UNSIGNED',
  TAX_AUTHORITY_DOCUMENTS_SIGNATURES_APPROVED = 'TAX_AUTHORITY_DOCUMENTS_SIGNATURES_APPROVED',
  VAT_BUSINESS_CREATION_DOCUMENT_ACCOUNTANT_SIGNED = 'VAT_BUSINESS_CREATION_DOCUMENT_ACCOUNTANT_SIGNED',
  VAT_BUSINESS_CREATION_DOCUMENT_BUSINESS_SIGNED = 'VAT_BUSINESS_CREATION_DOCUMENT_BUSINESS_SIGNED',
  VAT_BUSINESS_CREATION_DOCUMENT_SIGNATURES_APPROVED = 'VAT_BUSINESS_CREATION_DOCUMENT_SIGNATURES_APPROVED',
  VAT_BUSINESS_CREATION_DOCUMENT_UPLOAD_UNSIGNED = 'VAT_BUSINESS_CREATION_DOCUMENT_UPLOAD_UNSIGNED'
}

export type BusinessBilling = {
  __typename?: 'BusinessBilling';
  billingStatus: BusinessBillingStatus;
  business: Business;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  datePaid?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  paidForPeriod?: Maybe<Scalars['String']['output']>;
  paymeSaleAuthNumber?: Maybe<Scalars['String']['output']>;
  paymeSaleId?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum BusinessBillingStatus {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED'
}

export type BusinessBillingsChartDataInput = {
  billingsChartOrder: BillingsChartOrder;
};

export type BusinessBillingsChartDataOutput = {
  __typename?: 'BusinessBillingsChartDataOutput';
  countFaild?: Maybe<Array<Scalars['Int']['output']>>;
  date?: Maybe<Array<Scalars['String']['output']>>;
  totalFaild?: Maybe<Array<Scalars['Float']['output']>>;
  totalIncomes?: Maybe<Array<Scalars['Float']['output']>>;
  totalPaid?: Maybe<Array<Scalars['Float']['output']>>;
};

export type BusinessBillingsDataByDateInput = {
  date: Scalars['String']['input'];
};

export type BusinessBillingsDataByDateOutput = {
  __typename?: 'BusinessBillingsDataByDateOutput';
  countFaild: Scalars['Int']['output'];
  totalFaild: Scalars['Float']['output'];
  totalIncomes: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
  totalRefunded: Scalars['Float']['output'];
};

export type BusinessBillingsTable = {
  __typename?: 'BusinessBillingsTable';
  businessId: Scalars['String']['output'];
  businessName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
};

export type BusinessBillingsTableByDate = {
  __typename?: 'BusinessBillingsTableByDate';
  business: BusinessBillingsTable;
  totalOwed: Scalars['Float']['output'];
};

export type BusinessBillingsTableByDateInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type BusinessBillingsTableByDateOutput = {
  __typename?: 'BusinessBillingsTableByDateOutput';
  items?: Maybe<Array<BusinessBillingsTableByDate>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BusinessCustomer = {
  __typename?: 'BusinessCustomer';
  address?: Maybe<Scalars['String']['output']>;
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankBranch?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  businessId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  customerName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  saved: Scalars['Boolean']['output'];
};

export type BusinessDocument = {
  __typename?: 'BusinessDocument';
  lastModified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type BusinessExemption = {
  __typename?: 'BusinessExemption';
  bookKeepingAuthorization?: Maybe<Scalars['Boolean']['output']>;
  bookKeepingAuthorizationEndDate?: Maybe<Scalars['String']['output']>;
  bookKeepingAuthorizationProductionDate?: Maybe<Scalars['String']['output']>;
  bookKeepingAuthorizationStartDate?: Maybe<Scalars['String']['output']>;
  businessId: Scalars['ID']['output'];
  certainWageTypesRate?: Maybe<Scalars['Int']['output']>;
  certificateValidityEndDate?: Maybe<Scalars['String']['output']>;
  certificateValidityStartDate?: Maybe<Scalars['String']['output']>;
  constructionAndTransportationWorksRate?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  insuranceCommissionRate?: Maybe<Scalars['Int']['output']>;
  interestAndCapitalMarketRate?: Maybe<Scalars['Int']['output']>;
  rentPaymentRate?: Maybe<Scalars['Int']['output']>;
  servicesOrPropertiesAndMoreRate?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type BusinessExpense = {
  __typename?: 'BusinessExpense';
  accountantId: Scalars['ID']['output'];
  business: Business;
  businessId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  currencyRate: Scalars['Float']['output'];
  disputeStatus?: Maybe<Scalars['String']['output']>;
  expenseDate: Scalars['String']['output'];
  expenseNumber?: Maybe<Scalars['String']['output']>;
  expenseStatus: BusinessExpenseStatus;
  expenseType?: Maybe<BusinessExpenseType>;
  fullPrice: Scalars['Float']['output'];
  geminaJson?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<BusinessExpenseItem>>;
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  provider?: Maybe<BusinessProvider>;
  providerId?: Maybe<Scalars['ID']['output']>;
  report: BusinessReport;
  reportId: Scalars['ID']['output'];
  taxDeduction: Scalars['Float']['output'];
  totalDeductedTax?: Maybe<Scalars['Float']['output']>;
  totalDeductedVat?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  vat: Scalars['Float']['output'];
  vatPercentage: Scalars['Float']['output'];
  vatType: BusinessExpenseVatType;
};

export enum BusinessExpenseDisputeStatus {
  CANCEL_BUSINESS = 'CANCEL_BUSINESS',
  DUPLICATED_EXPENSE = 'DUPLICATED_EXPENSE',
  INVALID_DOCUMENT_TYPE = 'INVALID_DOCUMENT_TYPE',
  NOT_DEDUCTABLE = 'NOT_DEDUCTABLE',
  UNCLEAR_DOCUMENT_SCAN = 'UNCLEAR_DOCUMENT_SCAN'
}

export type BusinessExpenseItem = {
  __typename?: 'BusinessExpenseItem';
  category?: Maybe<TaxCategory>;
  deductedTax: Scalars['Float']['output'];
  deductedVat: Scalars['Float']['output'];
  expenseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  irsCode: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  taxDeductionPercentage: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
  vatDeductionPercentage: Scalars['Float']['output'];
  vatType?: Maybe<TaxCategoryVatType>;
};

export type BusinessExpenseItemInput = {
  deductedTax?: InputMaybe<Scalars['Float']['input']>;
  deductedVat?: InputMaybe<Scalars['Float']['input']>;
  irsCode: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  taxDeductionPercentage: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
  vatDeductionPercentage: Scalars['Float']['input'];
  vatType: TaxCategoryVatType;
};

export enum BusinessExpenseStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  GEMINA_PROCESSING_FAILED = 'GEMINA_PROCESSING_FAILED',
  GEMINA_PROCESSING_IN_PROGRESS = 'GEMINA_PROCESSING_IN_PROGRESS',
  IN_DISPUTE = 'IN_DISPUTE',
  PENDING_ACCOUNTANT_APPROVAL = 'PENDING_ACCOUNTANT_APPROVAL'
}

export enum BusinessExpenseType {
  CREDIT_NOTE = 'CREDIT_NOTE',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
  DONATION_RECEIPT = 'DONATION_RECEIPT',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  RECEIPT = 'RECEIPT',
  RECEIPT_TAX_INVOICE = 'RECEIPT_TAX_INVOICE',
  REFUND_INVOICE = 'REFUND_INVOICE',
  REFUND_RECEIPT = 'REFUND_RECEIPT',
  REGULAR = 'REGULAR',
  TAX_INVOICE = 'TAX_INVOICE',
  UNCLASSIFIED = 'UNCLASSIFIED'
}

export enum BusinessExpenseVatType {
  REGULAR_VAT = 'REGULAR_VAT',
  VAT_FREE = 'VAT_FREE',
  VAT_ZERO = 'VAT_ZERO'
}

export type BusinessFile = {
  __typename?: 'BusinessFile';
  lastModified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type BusinessIncome = {
  __typename?: 'BusinessIncome';
  accountantId: Scalars['ID']['output'];
  business: Business;
  businessId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  currencyRate: Scalars['Float']['output'];
  customer: BusinessCustomer;
  customerId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<BusinessMailer>>;
  english?: Maybe<Scalars['Boolean']['output']>;
  fullPrice: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  incomeDate: Scalars['String']['output'];
  incomeProvider?: Maybe<BusinessIncomeProvider>;
  incomeStatus: BusinessIncomeStatus;
  incomeType: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  items?: Maybe<Array<BusinessIncomeItem>>;
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<BusinessIncomePayment>>;
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  reportId: Scalars['ID']['output'];
  taoaConfirmationNumber?: Maybe<Scalars['String']['output']>;
  totalDeductedTax?: Maybe<Scalars['Float']['output']>;
  totalDeductedVat?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  vat: Scalars['Float']['output'];
  vatPercentage: Scalars['Float']['output'];
  vatType: Scalars['String']['output'];
};

export type BusinessIncomeCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum BusinessIncomeImportRange {
  CURRENT_AND_LAST_YEAR = 'CURRENT_AND_LAST_YEAR',
  CURRENT_AND_TWO_LAST_YEARS = 'CURRENT_AND_TWO_LAST_YEARS',
  CURRENT_YEAR = 'CURRENT_YEAR'
}

export type BusinessIncomeItem = {
  __typename?: 'BusinessIncomeItem';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  incomeId: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  units: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type BusinessIncomeItemsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  priceIncludingVat: Scalars['Float']['input'];
  unitPrice: Scalars['Float']['input'];
  units: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type BusinessIncomePayment = {
  __typename?: 'BusinessIncomePayment';
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankBranch?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  chequeNum?: Maybe<Scalars['String']['output']>;
  creditCardLastDigits?: Maybe<Scalars['String']['output']>;
  creditCardNumberOfPayments?: Maybe<Scalars['Float']['output']>;
  creditCardPaymentType?: Maybe<Scalars['String']['output']>;
  creditCardType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomeId: Scalars['ID']['output'];
  paymentApp?: Maybe<BusinessIncomePaymentApp>;
  paymentAppDealNumber?: Maybe<Scalars['String']['output']>;
  paymentDate: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  taxDeduction: Scalars['Float']['output'];
};

export enum BusinessIncomePaymentApp {
  BIT = 'BIT',
  PAY = 'PAY',
  PAYBOX = 'PAYBOX'
}

export enum BusinessIncomePaymentCreditCardPaymentType {
  PAYMENTS = 'PAYMENTS',
  REGULAR = 'REGULAR'
}

export enum BusinessIncomePaymentCreditCardType {
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DINERS = 'DINERS',
  ISRACARD = 'ISRACARD',
  MASTERCARD = 'MASTERCARD',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA'
}

export enum BusinessIncomePaymentType {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CHEQUE = 'CHEQUE',
  CREDIT_CARD = 'CREDIT_CARD',
  OTHER = 'OTHER',
  PAYMENT_APP = 'PAYMENT_APP',
  TAX_DEDUCTION = 'TAX_DEDUCTION'
}

export type BusinessIncomePaymentsInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankBranch?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  chequeNum?: InputMaybe<Scalars['String']['input']>;
  creditCardLastDigits?: InputMaybe<Scalars['String']['input']>;
  creditCardNumberOfPayments?: InputMaybe<Scalars['Int']['input']>;
  creditCardPaymentType?: InputMaybe<BusinessIncomePaymentCreditCardPaymentType>;
  creditCardType?: InputMaybe<BusinessIncomePaymentCreditCardType>;
  date: Scalars['String']['input'];
  paymentApp?: InputMaybe<BusinessIncomePaymentApp>;
  paymentAppDealNumber?: InputMaybe<Scalars['String']['input']>;
  paymentType: BusinessIncomePaymentType;
  price: Scalars['Float']['input'];
  taxDeduction?: InputMaybe<Scalars['Float']['input']>;
};

export enum BusinessIncomeProvider {
  BOOSTAPP = 'BOOSTAPP',
  CARDCOM = 'CARDCOM',
  CASPITLIGHT = 'CASPITLIGHT',
  EASY_COUNT = 'EASY_COUNT',
  GREENINVOICE = 'GREENINVOICE',
  ICOUNT = 'ICOUNT',
  JONNI = 'JONNI',
  MANUAL = 'MANUAL',
  MESHULAM = 'MESHULAM',
  MYCOUNT = 'MYCOUNT',
  SUMIT = 'SUMIT',
  VERIFONE = 'VERIFONE',
  WOLT = 'WOLT',
  YAAD_PAY = 'YAAD_PAY',
  YPAY = 'YPAY'
}

export enum BusinessIncomeStatus {
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  PARTIAL_REFUNDED_CLOSED = 'PARTIAL_REFUNDED_CLOSED'
}

export enum BusinessIncomeType {
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  RECEIPT = 'RECEIPT',
  RECEIPT_TAX_INVOICE = 'RECEIPT_TAX_INVOICE',
  REFUND_INVOICE = 'REFUND_INVOICE',
  REFUND_INVOICE_RECEIPT = 'REFUND_INVOICE_RECEIPT',
  REFUND_RECEIPT = 'REFUND_RECEIPT',
  TAX_INVOICE = 'TAX_INVOICE'
}

export enum BusinessIncomeVatType {
  REGULAR_VAT = 'REGULAR_VAT',
  VAT_FREE = 'VAT_FREE'
}

export type BusinessIndustry = {
  __typename?: 'BusinessIndustry';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  irsCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type BusinessMailer = {
  __typename?: 'BusinessMailer';
  attachments: Array<EmailAttachment>;
  businessId: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  emailSentAt?: Maybe<Scalars['String']['output']>;
  emailStatus: EmailStatus;
  id: Scalars['ID']['output'];
  recipient: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type BusinessNotification = {
  __typename?: 'BusinessNotification';
  businessId: Scalars['ID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  read: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  values: Array<NotificationValues>;
};

export type BusinessProvider = {
  __typename?: 'BusinessProvider';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isIsraelProvider: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  source: BusinessProviderSource;
  updatedAt: Scalars['String']['output'];
};

export enum BusinessProviderSource {
  ACCOUNTANT = 'ACCOUNTANT',
  API = 'API',
  GEMINA = 'GEMINA',
  MANUAL = 'MANUAL'
}

export type BusinessRecentIncomeItem = {
  __typename?: 'BusinessRecentIncomeItem';
  description: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
};

export type BusinessReport = {
  __typename?: 'BusinessReport';
  accountantId: Scalars['ID']['output'];
  advanceTaxRate: Scalars['Float']['output'];
  bituahLeumiPaymentFirstTerm: Scalars['Float']['output'];
  bituahLeumiPaymentSecondTerm: Scalars['Float']['output'];
  business: Business;
  businessApprovedAt?: Maybe<Scalars['String']['output']>;
  businessId: Scalars['ID']['output'];
  businessType: BusinessType;
  endDate: Scalars['String']['output'];
  expensesTotal: Scalars['Float']['output'];
  expensesTotalMinusVat: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  incomesTotal: Scalars['Float']['output'];
  incomesTotalMinusVat: Scalars['Float']['output'];
  incomesTotalMinusVatRound: Scalars['Float']['output'];
  incomesTotalRegularMinusVat: Scalars['Float']['output'];
  incomesTotalRegularMinusVatRound: Scalars['Float']['output'];
  incomesTotalTax: Scalars['Float']['output'];
  incomesTotalVATFree: Scalars['Float']['output'];
  incomesTotalVat: Scalars['Float']['output'];
  pendingApprovalExpenses?: Maybe<Scalars['Int']['output']>;
  prepaidTax: Scalars['Float']['output'];
  reportStatus: BusinessReportStatus;
  reportType: BusinessReportType;
  startDate: Scalars['String']['output'];
  taxPayment: Scalars['Float']['output'];
  totalDeductedTax: Scalars['Float']['output'];
  totalDeductedVat: Scalars['Float']['output'];
  totalExpensesVatLongTerm: Scalars['Float']['output'];
  totalExpensesVatOther: Scalars['Float']['output'];
  vatPayment: Scalars['Float']['output'];
};

export enum BusinessReportStatus {
  ACTIVE = 'ACTIVE',
  AUTHORITIES_REPORTED = 'AUTHORITIES_REPORTED',
  BUSINESS_APPROVED = 'BUSINESS_APPROVED',
  BUSINESS_DECLINED = 'BUSINESS_DECLINED',
  NO_NEED_TO_REPORT = 'NO_NEED_TO_REPORT',
  PENDING_ACCOUNTANT_REVIEW = 'PENDING_ACCOUNTANT_REVIEW',
  PENDING_BUSINESS_RESPONSE = 'PENDING_BUSINESS_RESPONSE'
}

export enum BusinessReportType {
  BI_MONTHLY = 'BI_MONTHLY',
  MONTHLY = 'MONTHLY'
}

export type BusinessSetup = {
  __typename?: 'BusinessSetup';
  businessType: BusinessSetupBusinessType;
  createdAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasAccountant: Scalars['Boolean']['output'];
  hasBusiness: Scalars['Boolean']['output'];
  hasEmployees: Scalars['Boolean']['output'];
  invitationSent: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum BusinessSetupBusinessType {
  LTD = 'LTD',
  MURSHE = 'MURSHE',
  PATUR = 'PATUR'
}

export type BusinessSignTaxAuthorityAndBituahLeumiDocumentsInput = {
  base64Image: Scalars['String']['input'];
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  draftPhase: Scalars['Boolean']['input'];
};

export type BusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput = {
  __typename?: 'BusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput';
  data: Scalars['Boolean']['output'];
};

export type BusinessSignVatBusinessCreationDocumentInput = {
  base64Image: Scalars['String']['input'];
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  draftPhase: Scalars['Boolean']['input'];
};

export type BusinessSignVatBusinessCreationDocumentOutput = {
  __typename?: 'BusinessSignVatBusinessCreationDocumentOutput';
  data: Scalars['Boolean']['output'];
};

export type BusinessStatistics = {
  __typename?: 'BusinessStatistics';
  totalBilled: Scalars['Float']['output'];
};

export enum BusinessStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REGISTRATION_UNCOMPLETE = 'REGISTRATION_UNCOMPLETE'
}

export type BusinessStatusCounters = {
  __typename?: 'BusinessStatusCounters';
  ACTIVE?: Maybe<Scalars['Int']['output']>;
  ALL?: Maybe<Scalars['Int']['output']>;
  BEFORE_PENDING_APPROVAL?: Maybe<Scalars['Int']['output']>;
  BILLING_DETAILS?: Maybe<Scalars['Int']['output']>;
  BLOCKED?: Maybe<Scalars['Int']['output']>;
  CANCELLED?: Maybe<Scalars['Int']['output']>;
  ON_HOLD?: Maybe<Scalars['Int']['output']>;
  PENDING_APPROVAL?: Maybe<Scalars['Int']['output']>;
  REGISTRATION_UNCOMPLETE?: Maybe<Scalars['Int']['output']>;
};

export type BusinessSubscription = {
  __typename?: 'BusinessSubscription';
  businessId: Scalars['ID']['output'];
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
  subscriptionPlan?: Maybe<BusinessSubscriptionPlan>;
  subscriptionPlanId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type BusinessSubscriptionConverterInput = {
  businessId: Scalars['ID']['input'];
  isToCreateBilling: Scalars['Boolean']['input'];
  toPlanType: PlanType;
  toPlanTypeFree?: InputMaybe<PlanType>;
};

export type BusinessSubscriptionConverterOutput = {
  __typename?: 'BusinessSubscriptionConverterOutput';
  data: Scalars['Boolean']['output'];
};

export type BusinessSubscriptionPlan = {
  __typename?: 'BusinessSubscriptionPlan';
  businessType?: Maybe<BusinessType>;
  discountPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  planType: PlanType;
  price: Scalars['Float']['output'];
  printName?: Maybe<Scalars['String']['output']>;
  termInMonth?: Maybe<Scalars['Int']['output']>;
};

export enum BusinessType {
  MURSHE = 'MURSHE',
  PATUR = 'PATUR'
}

export type BusinessTypeConverterInput = {
  businessId: Scalars['ID']['input'];
  conversionDate: Scalars['String']['input'];
  toBusinessType: BusinessType;
};

export type BusinessTypeConverterOutput = {
  __typename?: 'BusinessTypeConverterOutput';
  data: Scalars['Boolean']['output'];
};

export type BusinessUploadMissingDocumentsInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type BusinessUploadMissingDocumentsOutput = {
  __typename?: 'BusinessUploadMissingDocumentsOutput';
  data: Scalars['Boolean']['output'];
};

export enum CalculationBusinessReportError {
  INVALID_BUSINESS = 'INVALID_BUSINESS',
  INVALID_BUSINESS_STATUS = 'INVALID_BUSINESS_STATUS',
  INVALID_REPORT_STATUS = 'INVALID_REPORT_STATUS'
}

export type CalculationBusinessReportInput = {
  businessId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type CalculationBusinessReportOutput = {
  __typename?: 'CalculationBusinessReportOutput';
  error?: Maybe<CalculationBusinessReportError>;
  success: Scalars['Boolean']['output'];
};

export type CanBeBlockedInput = {
  businessId: Scalars['ID']['input'];
};

export type CanBeBlockedOutput = {
  __typename?: 'CanBeBlockedOutput';
  data: Scalars['Boolean']['output'];
};

export enum CancelBusinessError {
  EXPENSES_PENDING_APPROVAL = 'EXPENSES_PENDING_APPROVAL',
  INVALID_BUSINESS_STATUS = 'INVALID_BUSINESS_STATUS',
  REPORTS_PENDING_APPROVAL = 'REPORTS_PENDING_APPROVAL'
}

export type CancelBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export type CancelBusinessOutput = {
  __typename?: 'CancelBusinessOutput';
  error?: Maybe<CancelBusinessError>;
  success: Scalars['Boolean']['output'];
};

export type ChargeFailedBusinessBillingInput = {
  billingId: Scalars['ID']['input'];
};

export type ChargeFailedBusinessBillingMobileInput = {
  businessId: Scalars['ID']['input'];
};

export type ChargeFailedBusinessBillingMobileOutput = {
  __typename?: 'ChargeFailedBusinessBillingMobileOutput';
  data: Scalars['Boolean']['output'];
};

export type CloseBusinessReportInput = {
  reportId: Scalars['ID']['input'];
  toReportStatus: Scalars['String']['input'];
};

export type CloseBusinessReportOutput = {
  __typename?: 'CloseBusinessReportOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reportStatus: BusinessReportStatus;
};

export type CompanySubscriptionPlan = {
  __typename?: 'CompanySubscriptionPlan';
  cancelled: Scalars['Boolean']['output'];
  companyName: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  planType?: Maybe<Scalars['String']['output']>;
  planTypeFree?: Maybe<Scalars['String']['output']>;
  referralId: Scalars['String']['output'];
  subscriptionPlanDiscountMursheId?: Maybe<Scalars['ID']['output']>;
  subscriptionPlanDiscountPaturId?: Maybe<Scalars['ID']['output']>;
  subscriptionPlanFreeId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type CreateBusinessExpenseInput = {
  businessId: Scalars['String']['input'];
  expense: Scalars['String']['input'];
};

export type CreateBusinessExpenseOutput = {
  expenseId: Scalars['String']['output'];
};

export type CreateBusinessIncomeInput = {
  base64Pdf?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  customer?: InputMaybe<BusinessIncomeCustomerInput>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  date: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  incomeDocumentComments?: InputMaybe<DocumentComments>;
  incomeProvider: Scalars['String']['input'];
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  items: Array<InputMaybe<BusinessIncomeItemsInput>>;
  parentIncomeId?: InputMaybe<Scalars['ID']['input']>;
  paymentDueDate?: InputMaybe<Scalars['String']['input']>;
  payments: Array<InputMaybe<BusinessIncomePaymentsInput>>;
  pdfLanguage?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  priceIncludingVat: Scalars['Float']['input'];
  saveCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  taxDeduction?: InputMaybe<Scalars['Float']['input']>;
  type: BusinessIncomeType;
  vat: Scalars['Float']['input'];
  vatType: BusinessIncomeVatType;
};

export type CreateBusinessIncomeOutput = {
  __typename?: 'CreateBusinessIncomeOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type CreateCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankBranch?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['String']['input'];
  customerName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerOutput = {
  __typename?: 'CreateCustomerOutput';
  data?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateProviderInput = {
  isIsraelProvider: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProviderOutput = {
  __typename?: 'CreateProviderOutput';
  id: Scalars['ID']['output'];
  isIsraelProvider: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type CreateTaxCategoryInput = {
  categoryName: Scalars['String']['input'];
  irsCode: Scalars['Int']['input'];
  profitLossCategory: TaxCategoryProfitLossCategory;
  taxDeductionPercentage: Scalars['Float']['input'];
  vatDeductionPercentage: Scalars['Float']['input'];
  vatType: TaxCategoryVatType;
};

export type CreateTaxCategoryOutput = {
  __typename?: 'CreateTaxCategoryOutput';
  categoryName: Scalars['String']['output'];
  irsCode: Scalars['Int']['output'];
  profitLossCategory: TaxCategoryProfitLossCategory;
  taxDeductionPercentage: Scalars['Float']['output'];
  vatDeductionPercentage: Scalars['Float']['output'];
  vatType: TaxCategoryVatType;
};

export type CreateUserNoteInput = {
  completed: Scalars['Boolean']['input'];
  createdBy: Scalars['ID']['input'];
  handledBy?: InputMaybe<Scalars['String']['input']>;
  note: Scalars['String']['input'];
  noteType: NotesTypes;
  userId: Scalars['ID']['input'];
};

export type CreateUserNoteOutput = {
  __typename?: 'CreateUserNoteOutput';
  data: Scalars['Boolean']['output'];
};

export type Credit = {
  __typename?: 'Credit';
  creditType: CreditTypeEnum;
  credits: Scalars['Float']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type CreditType = {
  __typename?: 'CreditType';
  creditType?: Maybe<CreditTypeEnum>;
  credits?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum CreditTypeEnum {
  AGE_BETWEEN_16_AND_18 = 'AGE_BETWEEN_16_AND_18',
  CHILD_1_TO_5 = 'CHILD_1_TO_5',
  CHILD_6_TO_17 = 'CHILD_6_TO_17',
  CHILD_ABOVE_18 = 'CHILD_ABOVE_18',
  CHILD_NEWBORN = 'CHILD_NEWBORN',
  CHILD_NO_POSSESSION_1_TO_5 = 'CHILD_NO_POSSESSION_1_TO_5',
  CHILD_NO_POSSESSION_NEWBORN = 'CHILD_NO_POSSESSION_NEWBORN',
  CITIZEN = 'CITIZEN',
  FEMALE = 'FEMALE',
  MARRIED = 'MARRIED',
  MARRIED_SECOND_TIME_AND_PAYS_ALIMONI_FOR_PREVIOUS_SPOUSE = 'MARRIED_SECOND_TIME_AND_PAYS_ALIMONI_FOR_PREVIOUS_SPOUSE',
  MARRIED_TO_WIDOWER_WITH_KIDS_BELOW_18 = 'MARRIED_TO_WIDOWER_WITH_KIDS_BELOW_18',
  PARENT_LIVES_ALONE_WITH_CHILD_AND_NO_SHARED_HOUSEHOLD = 'PARENT_LIVES_ALONE_WITH_CHILD_AND_NO_SHARED_HOUSEHOLD',
  PARENT_LIVES_ALONE_WITH_CHILD_AND_SHARED_HOUSEHOLD = 'PARENT_LIVES_ALONE_WITH_CHILD_AND_SHARED_HOUSEHOLD',
  PARENT_THAT_PAYS_ALIMONI_FOR_CHILD_NO_POSSESS = 'PARENT_THAT_PAYS_ALIMONI_FOR_CHILD_NO_POSSESS',
  SINGLE_PARENT = 'SINGLE_PARENT'
}

export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  DKK = 'DKK',
  EGP = 'EGP',
  EUR = 'EUR',
  GBP = 'GBP',
  ILS = 'ILS',
  JOD = 'JOD',
  JPY = 'JPY',
  LBP = 'LBP',
  NOK = 'NOK',
  SEK = 'SEK',
  USD = 'USD',
  ZAR = 'ZAR'
}

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  currency: Currency;
  rate: Scalars['Float']['output'];
};

export type CustomerImportedIncomes = {
  __typename?: 'CustomerImportedIncomes';
  customerIdNumber?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
};

export type DeclineBusinessExpenseDisputeInput = {
  businessId: Scalars['String']['input'];
  expenseId: Scalars['String']['input'];
};

export type DeleteBusinessExpenseInput = {
  expenseId: Scalars['ID']['input'];
};

export type DeleteBusinessFileInput = {
  businessId: Scalars['ID']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
  folder: Scalars['String']['input'];
};

export type DeleteBusinessFileOutput = {
  __typename?: 'DeleteBusinessFileOutput';
  data: Scalars['Boolean']['output'];
};

export type DeleteUserNotificationTokenInput = {
  fcmToken: Scalars['String']['input'];
};

export type DiscountPhoneNumber = {
  __typename?: 'DiscountPhoneNumber';
  createdAt?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['String']['output'];
  planType: PlanType;
  planTypeFree?: Maybe<PlanType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DisputeBusinessExpenseInput = {
  businessId: Scalars['ID']['input'];
  disputeStatus: BusinessExpenseDisputeStatus;
  expenseId: Scalars['ID']['input'];
};

export type DisputeBusinessExpenseOutput = {
  __typename?: 'DisputeBusinessExpenseOutput';
  disputeStatus: BusinessExpenseDisputeStatus;
  expenseStatus: BusinessExpenseStatus;
  id: Scalars['ID']['output'];
};

export type DocumentComments = {
  footer?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  main?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentTree = {
  __typename?: 'DocumentTree';
  data?: Maybe<DocumentTreeData>;
  depth?: Maybe<Scalars['Int']['output']>;
};

export type DocumentTreeChildrenAllowedData = {
  __typename?: 'DocumentTreeChildrenAllowedData';
  currency: Scalars['String']['output'];
  customer: DocumentTreeCustomer;
  customerId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  incomeType: Scalars['String']['output'];
  items: Array<Maybe<DocumentTreeItem>>;
  payments: Array<Maybe<DocumentTreePayment>>;
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type DocumentTreeCustomer = {
  __typename?: 'DocumentTreeCustomer';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type DocumentTreeData = {
  __typename?: 'DocumentTreeData';
  accountId?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  costumerId?: Maybe<Scalars['String']['output']>;
  createAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currencyRate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destId?: Maybe<Scalars['String']['output']>;
  fullPrice?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  incomeDate?: Maybe<Scalars['String']['output']>;
  incomeProvider?: Maybe<Scalars['String']['output']>;
  incomeType?: Maybe<Scalars['String']['output']>;
  incomesStatus?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priceIncludingVat?: Maybe<Scalars['String']['output']>;
  reportEndDate?: Maybe<Scalars['String']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportStartDate?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
  srcId?: Maybe<Scalars['String']['output']>;
  totalDeductedTax?: Maybe<Scalars['String']['output']>;
  totalDeductedVat?: Maybe<Scalars['String']['output']>;
  treeId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
  vatPercentage?: Maybe<Scalars['String']['output']>;
  vatType?: Maybe<Scalars['String']['output']>;
};

export type DocumentTreeInput = {
  srcId: Scalars['String']['input'];
};

export type DocumentTreeItem = {
  __typename?: 'DocumentTreeItem';
  description: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  units: Scalars['Int']['output'];
  vat: Scalars['Float']['output'];
};

export type DocumentTreeOutput = {
  __typename?: 'DocumentTreeOutput';
  childrenAllowedData?: Maybe<Array<Maybe<DocumentTreeChildrenAllowedData>>>;
  items?: Maybe<Array<Maybe<DocumentTree>>>;
};

export type DocumentTreePayment = {
  __typename?: 'DocumentTreePayment';
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
};

export type DraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsInput = {
  base64Image: Scalars['String']['input'];
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  draftPhase: Scalars['Boolean']['input'];
};

export type DraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput = {
  __typename?: 'DraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput';
  data: Array<PdfDataOutput>;
};

export type DraftBusinessSignVatBusinessCreationDocumentInput = {
  base64Image: Scalars['String']['input'];
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  draftPhase: Scalars['Boolean']['input'];
};

export type DraftBusinessSignVatBusinessCreationDocumentOutput = {
  __typename?: 'DraftBusinessSignVatBusinessCreationDocumentOutput';
  data: Array<PdfDataOutput>;
};

export type EditCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankBranch?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['String']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type EditCustomerOutput = {
  __typename?: 'EditCustomerOutput';
  data?: Maybe<Scalars['Boolean']['output']>;
};

export type EditDinVeHeshbonToDbInput = {
  addressEnterance?: InputMaybe<Scalars['String']['input']>;
  addressNumber: Scalars['Int']['input'];
  addresseeAddressEnterance?: InputMaybe<Scalars['String']['input']>;
  addresseeAddressNumber?: InputMaybe<Scalars['Int']['input']>;
  addresseeApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  addresseeCity?: InputMaybe<Scalars['String']['input']>;
  addresseeName?: InputMaybe<Scalars['String']['input']>;
  addresseeStreet?: InputMaybe<Scalars['String']['input']>;
  addresseeZipcode?: InputMaybe<Scalars['String']['input']>;
  agentApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  agentCity?: InputMaybe<Scalars['String']['input']>;
  agentName?: InputMaybe<Scalars['String']['input']>;
  agentPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  agentStreetAddress?: InputMaybe<Scalars['String']['input']>;
  agentStreetNumber?: InputMaybe<Scalars['Int']['input']>;
  agentZipcode?: InputMaybe<Scalars['Int']['input']>;
  apartmentNumber: Scalars['Int']['input'];
  bankAccountNumber: Scalars['Int']['input'];
  bankBranchName: Scalars['String']['input'];
  bankBranchNumber: Scalars['Int']['input'];
  bankName: Scalars['String']['input'];
  businessApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  businessCity: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  businessPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  businessStreetAddress: Scalars['String']['input'];
  businessStreetNumber?: InputMaybe<Scalars['Int']['input']>;
  businessZipcode?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyStatus: FamilyStatusOptions;
  familyStatusSinceDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  idNumber: Scalars['String']['input'];
  isApproveMessages: Scalars['Boolean']['input'];
  isApproveMessaging?: InputMaybe<Scalars['Boolean']['input']>;
  isMailDifferent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  mobilePhoneNumber: Scalars['String']['input'];
  occupationsLastTwoYears: Array<InputMaybe<SingleOccupationOptions>>;
  partnerFirstName?: InputMaybe<Scalars['String']['input']>;
  partnerIdNumber?: InputMaybe<Scalars['String']['input']>;
  partnerLastName?: InputMaybe<Scalars['String']['input']>;
  selfEmployedStart?: InputMaybe<SelfEmployedStartObject>;
  sourceOfIncomes: Array<InputMaybe<SourceOfIncomes>>;
  streetAddress: Scalars['String']['input'];
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

export type EditDinVeHeshbonToDbOutput = {
  __typename?: 'EditDinVeHeshbonToDbOutput';
  message: Scalars['String']['output'];
};

export type EditProviderInput = {
  id: Scalars['ID']['input'];
  isIsraelProvider: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type EditProviderOutput = {
  __typename?: 'EditProviderOutput';
  id: Scalars['ID']['output'];
  isIsraelProvider: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type EditTaxCategoryInput = {
  categoryName: Scalars['String']['input'];
  irsCode: Scalars['Int']['input'];
  profitLossCategory: TaxCategoryProfitLossCategory;
  taxDeductionPercentage: Scalars['Float']['input'];
  vatDeductionPercentage: Scalars['Float']['input'];
  vatType: TaxCategoryVatType;
};

export type EditTaxCategoryOutput = {
  __typename?: 'EditTaxCategoryOutput';
  categoryName: Scalars['String']['output'];
  irsCode: Scalars['Int']['output'];
  profitLossCategory: TaxCategoryProfitLossCategory;
  taxDeductionPercentage: Scalars['Float']['output'];
  vatDeductionPercentage: Scalars['Float']['output'];
  vatType: TaxCategoryVatType;
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  cid: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type EmailAttachmentInput = {
  cid: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export enum EmailStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export enum FamilyStatusOptions {
  DIVORCEE = 'DIVORCEE',
  KNOWN_IN_PUBLIC = 'KNOWN_IN_PUBLIC',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOWER = 'WIDOWER'
}

export type File = {
  __typename?: 'File';
  url?: Maybe<Scalars['String']['output']>;
};

export type FillDinVeHeshbonOutput = {
  __typename?: 'FillDinVeHeshbonOutput';
  data: Scalars['Boolean']['output'];
};

export type GenerateCardIndexInput = {
  businessId: Scalars['String']['input'];
  language: Scalars['String']['input'];
  taxYear: Scalars['Int']['input'];
};

export type GenerateCardIndexOutput = {
  __typename?: 'GenerateCardIndexOutput';
  base64Pdf: Scalars['String']['output'];
};

export type GenerateJonniIncomeReportInput = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type GenerateOpenfrmtFileDocument = {
  __typename?: 'GenerateOpenfrmtFileDocument';
  amount: Scalars['Float']['output'];
  counter: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GenerateOpenfrmtFileEntry = {
  __typename?: 'GenerateOpenfrmtFileEntry';
  entry: Scalars['String']['output'];
  title: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type GenerateOpenfrmtFileInput = {
  businessId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type GenerateOpenfrmtFileOutput = {
  __typename?: 'GenerateOpenfrmtFileOutput';
  businessName: Scalars['String']['output'];
  documents: Array<GenerateOpenfrmtFileDocument>;
  endDate: Scalars['String']['output'];
  entries: Array<GenerateOpenfrmtFileEntry>;
  registrationNumber: Scalars['String']['output'];
  route: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type GenerateProfitLossDocumentInput = {
  businessId: Scalars['ID']['input'];
  year: Scalars['String']['input'];
};

export type GenerateProfitLossDocumentOutput = {
  __typename?: 'GenerateProfitLossDocumentOutput';
  base64Pdf: Scalars['String']['output'];
};

export type GetAccountantSignatureInput = {
  accountantId: Scalars['ID']['input'];
};

export type GetAccountantSignatureUploadUrlInput = {
  accountantId: Scalars['ID']['input'];
};

export type GetBusinessFileInput = {
  businessId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
};

export type GetBusinessFileUploadUrlInput = {
  businessId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
};

export type GetBusinessIncomesCeilingForBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export type GetBusinessIncomesCeilingForBusinessOutput = {
  __typename?: 'GetBusinessIncomesCeilingForBusinessOutput';
  limit?: Maybe<Scalars['Float']['output']>;
  totalIncomes?: Maybe<Scalars['Float']['output']>;
  totalIncomesUnused?: Maybe<Scalars['Float']['output']>;
  unUsedPercentage?: Maybe<Scalars['Float']['output']>;
  usedPercentage?: Maybe<Scalars['Float']['output']>;
};

export type GetBusinessPendingPaymentsDataOutput = {
  __typename?: 'GetBusinessPendingPaymentsDataOutput';
  businessStatus: BusinessStatus;
  numberOfPendingPayments: Scalars['Int']['output'];
};

export type GetCountBusinessFailedBillingsResponse = {
  __typename?: 'GetCountBusinessFailedBillingsResponse';
  total: Scalars['Int']['output'];
};

export type GetCountOpenfrmtFiles = {
  __typename?: 'GetCountOpenfrmtFiles';
  amount: Scalars['String']['output'];
  counter: Scalars['Int']['output'];
  incomeType: BusinessIncomeType;
  title: Scalars['String']['output'];
  totalA100: Scalars['Int']['output'];
  totalB100: Scalars['Int']['output'];
  totalB110: Scalars['Int']['output'];
  totalC100: Scalars['Int']['output'];
  totalD110: Scalars['Int']['output'];
  totalD120: Scalars['Int']['output'];
  totalM100: Scalars['Int']['output'];
  totalZ900: Scalars['Int']['output'];
};

export type GetCurrencyRateInput = {
  currency: Currency;
  date: Scalars['String']['input'];
};

export type GetDataFromPdfInput = {
  base64PdfArray: Array<Scalars['String']['input']>;
  businessId: Scalars['String']['input'];
};

export type GetDataFromPdfOutput = {
  __typename?: 'GetDataFromPdfOutput';
  base64Pdf: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyRate: Scalars['Float']['output'];
  customerName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fullPrice: Scalars['Float']['output'];
  fullPriceDeviation: Scalars['Float']['output'];
  incomeDate: Scalars['String']['output'];
  incomeProvider: Scalars['String']['output'];
  incomeType: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  items: Array<ImportBusinessIncomeItemsOutput>;
  payments: Array<ImportBusinessIncomePaymentsOutput>;
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  pricePaid: Scalars['Float']['output'];
  registrationNumber: Scalars['String']['output'];
  taxDeduction: Scalars['Float']['output'];
  totalDeductedTax: Scalars['Float']['output'];
  totalDeductedVat: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
  vatType: Scalars['String']['output'];
};

export type GetDinVeHeshbonData = {
  __typename?: 'GetDinVeHeshbonData';
  addressEnterance?: Maybe<Scalars['String']['output']>;
  addressNumber?: Maybe<Scalars['Int']['output']>;
  addresseeAddressEnterance?: Maybe<Scalars['String']['output']>;
  addresseeAddressNumber?: Maybe<Scalars['Int']['output']>;
  addresseeApartmentNumber?: Maybe<Scalars['Int']['output']>;
  addresseeCity?: Maybe<Scalars['String']['output']>;
  addresseeName?: Maybe<Scalars['String']['output']>;
  addresseeStreet?: Maybe<Scalars['String']['output']>;
  addresseeZipcode?: Maybe<Scalars['String']['output']>;
  agentApartmentNumber?: Maybe<Scalars['Int']['output']>;
  agentCity?: Maybe<Scalars['String']['output']>;
  agentName?: Maybe<Scalars['String']['output']>;
  agentPhoneNumber?: Maybe<Scalars['String']['output']>;
  agentStreetAddress?: Maybe<Scalars['String']['output']>;
  agentStreetNumber?: Maybe<Scalars['Int']['output']>;
  agentZipcode?: Maybe<Scalars['Int']['output']>;
  apartmentNumber?: Maybe<Scalars['Int']['output']>;
  bankAccountNumber?: Maybe<Scalars['Int']['output']>;
  bankBranchName?: Maybe<Scalars['String']['output']>;
  bankBranchNumber?: Maybe<Scalars['Int']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  businessApartmentNumber?: Maybe<Scalars['Int']['output']>;
  businessCity?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  businessPhoneNumber?: Maybe<Scalars['String']['output']>;
  businessStreetAddress?: Maybe<Scalars['String']['output']>;
  businessStreetNumber?: Maybe<Scalars['Int']['output']>;
  businessZipcode?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  familyStatus?: Maybe<FamilyStatusOptions>;
  familyStatusSinceDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homePhoneNumber?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  isApproveMessages?: Maybe<Scalars['Boolean']['output']>;
  isApproveMessaging?: Maybe<Scalars['Boolean']['output']>;
  isMailDifferent?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  occupationsLastTwoYears?: Maybe<Array<Maybe<SingleOccupationOptionsX>>>;
  partnerFirstName?: Maybe<Scalars['String']['output']>;
  partnerIdNumber?: Maybe<Scalars['String']['output']>;
  partnerLastName?: Maybe<Scalars['String']['output']>;
  selfEmployedStart?: Maybe<SelfEmployedStartObjectX>;
  sourceOfIncomes?: Maybe<Array<Maybe<SourceOfIncomesX>>>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

export type GetDinVeHeshbonDataInput = {
  businessId: Scalars['ID']['input'];
};

export type GetDinVeHeshbonDataOutput = {
  __typename?: 'GetDinVeHeshbonDataOutput';
  data?: Maybe<GetDinVeHeshbonData>;
};

export type GetListYearsForBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export type GetListYearsForBusinessOutput = {
  __typename?: 'GetListYearsForBusinessOutput';
  years: Array<Scalars['Int']['output']>;
};

export type GetNextBusinessSubscriptionPaymentInput = {
  businessId: Scalars['ID']['input'];
};

export type GetNextBusinessSubscriptionPaymentResponse = {
  __typename?: 'GetNextBusinessSubscriptionPaymentResponse';
  date: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type GetNextIncomeInvoiceNumberInput = {
  businessId: Scalars['ID']['input'];
  incomeType: BusinessIncomeType;
};

export type GetPreviousIncomeInput = {
  businessId: Scalars['String']['input'];
  incomeType: Scalars['String']['input'];
};

export type GetPreviousIncomeOutput = {
  __typename?: 'GetPreviousIncomeOutput';
  previousIncomeDate?: Maybe<Scalars['String']['output']>;
};

export type GetStatisticsForBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export enum HoldBusinessError {
  INVALID_BUSINESS_STATUS = 'INVALID_BUSINESS_STATUS'
}

export type HoldBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export type HoldBusinessOutput = {
  __typename?: 'HoldBusinessOutput';
  error?: Maybe<HoldBusinessError>;
  success: Scalars['Boolean']['output'];
};

export type HomeTabsCounters = {
  __typename?: 'HomeTabsCounters';
  BUSINESSES_PENDING_APPROVAL?: Maybe<Scalars['Int']['output']>;
  BUSINESS_PENDING_DOCUMENTS_TO_UPLOAD?: Maybe<Scalars['Int']['output']>;
  EXPENSE_PENDING_APPROVAL?: Maybe<Scalars['Int']['output']>;
};

export enum IdentificationType {
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  PARENT_ID_NUMBER = 'PARENT_ID_NUMBER',
  PASSPORT = 'PASSPORT'
}

export type ImportBusinessIncomeInput = {
  base64Pdf?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  currencyRate: Scalars['Float']['input'];
  customerName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fullPrice: Scalars['Float']['input'];
  incomeDate: Scalars['String']['input'];
  incomeProvider: BusinessIncomeProvider;
  incomeType: BusinessIncomeType;
  invoiceNumber: Scalars['Int']['input'];
  items: Array<ImportBusinessIncomeItemsInput>;
  payments?: InputMaybe<Array<InputMaybe<ImportBusinessIncomePaymentsInput>>>;
  price: Scalars['Float']['input'];
  priceIncludingVat: Scalars['Float']['input'];
  reportId: Scalars['String']['input'];
  taxDeduction: Scalars['Float']['input'];
  totalDeductedTax: Scalars['Float']['input'];
  totalDeductedVat: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
  vatType: BusinessIncomeVatType;
};

export type ImportBusinessIncomeItemsInput = {
  description: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  priceIncludingVat: Scalars['Float']['input'];
  unitPrice: Scalars['Float']['input'];
  units: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type ImportBusinessIncomeItemsOutput = {
  __typename?: 'ImportBusinessIncomeItemsOutput';
  description: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  units: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type ImportBusinessIncomeOutput = {
  __typename?: 'ImportBusinessIncomeOutput';
  id: Scalars['ID']['output'];
};

export type ImportBusinessIncomePaymentsInput = {
  paymentDate: Scalars['String']['input'];
  paymentType: BusinessIncomePaymentType;
  price: Scalars['Float']['input'];
  taxDeduction?: InputMaybe<Scalars['Float']['input']>;
};

export type ImportBusinessIncomePaymentsOutput = {
  __typename?: 'ImportBusinessIncomePaymentsOutput';
  paymentDate: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type ImportDataFromCsvOrExeclInput = {
  base64Files: Array<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  fileNames: Array<Scalars['String']['input']>;
  importRange: BusinessIncomeImportRange;
  isOverwriteIncomesEnabled: Scalars['Boolean']['input'];
  overrideIncomeProvider?: InputMaybe<BusinessIncomeProvider>;
};

export type ImportDataFromCsvOrExeclOutput = {
  __typename?: 'ImportDataFromCsvOrExeclOutput';
  failed?: Maybe<Array<Maybe<ImportedIncomes>>>;
  succeeded?: Maybe<Array<Maybe<ImportedIncomes>>>;
};

export type ImportedIncomes = {
  __typename?: 'ImportedIncomes';
  currency: Scalars['String']['output'];
  currencyRate: Scalars['Float']['output'];
  customer?: Maybe<CustomerImportedIncomes>;
  description?: Maybe<Scalars['String']['output']>;
  fullPrice: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  incomeDate: Scalars['String']['output'];
  incomeProvider: Scalars['String']['output'];
  incomeType: Scalars['String']['output'];
  invoiceNumber: Scalars['Float']['output'];
  items?: Maybe<Array<Maybe<ItemImportedIncomes>>>;
  payments?: Maybe<Array<Maybe<PaymentImportedIncomes>>>;
  price: Scalars['Float']['output'];
  priceIncludingVat: Scalars['Float']['output'];
  pricePaid: Scalars['Float']['output'];
  reason?: Maybe<ReasonImportedIncomes>;
  taxDeduction?: Maybe<Scalars['Float']['output']>;
  totalDeductedTax?: Maybe<Scalars['Float']['output']>;
  totalDeductedVat?: Maybe<Scalars['Float']['output']>;
  vat: Scalars['Float']['output'];
  vatType: Scalars['String']['output'];
};

export type InsertDinVeHeshbonToDbInput = {
  addressEnterance?: InputMaybe<Scalars['String']['input']>;
  addressNumber: Scalars['Int']['input'];
  addresseeAddressEnterance?: InputMaybe<Scalars['String']['input']>;
  addresseeAddressNumber?: InputMaybe<Scalars['Int']['input']>;
  addresseeApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  addresseeCity?: InputMaybe<Scalars['String']['input']>;
  addresseeName?: InputMaybe<Scalars['String']['input']>;
  addresseeStreet?: InputMaybe<Scalars['String']['input']>;
  addresseeZipcode?: InputMaybe<Scalars['String']['input']>;
  agentApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  agentCity?: InputMaybe<Scalars['String']['input']>;
  agentName?: InputMaybe<Scalars['String']['input']>;
  agentPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  agentStreetAddress?: InputMaybe<Scalars['String']['input']>;
  agentStreetNumber?: InputMaybe<Scalars['Int']['input']>;
  agentZipcode?: InputMaybe<Scalars['Int']['input']>;
  apartmentNumber: Scalars['Int']['input'];
  bankAccountNumber: Scalars['Int']['input'];
  bankBranchName: Scalars['String']['input'];
  bankBranchNumber: Scalars['Int']['input'];
  bankName: Scalars['String']['input'];
  businessApartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  businessApprovalStatus?: InputMaybe<Scalars['String']['input']>;
  businessCity: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  businessPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  businessStreetAddress: Scalars['String']['input'];
  businessStreetNumber?: InputMaybe<Scalars['Int']['input']>;
  businessZipcode?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyStatus: FamilyStatusOptions;
  familyStatusSinceDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  idNumber: Scalars['String']['input'];
  isApproveMessages: Scalars['Boolean']['input'];
  isApproveMessaging?: InputMaybe<Scalars['Boolean']['input']>;
  isMailDifferent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  mobilePhoneNumber: Scalars['String']['input'];
  occupationsLastTwoYears: Array<InputMaybe<SingleOccupationOptions>>;
  partnerFirstName?: InputMaybe<Scalars['String']['input']>;
  partnerIdNumber?: InputMaybe<Scalars['String']['input']>;
  partnerLastName?: InputMaybe<Scalars['String']['input']>;
  selfEmployedStart?: InputMaybe<SelfEmployedStartObject>;
  sourceOfIncomes: Array<InputMaybe<SourceOfIncomes>>;
  streetAddress: Scalars['String']['input'];
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

export type InsertDinVeHeshbonToDbOutput = {
  __typename?: 'InsertDinVeHeshbonToDbOutput';
  message: Scalars['String']['output'];
};

export type IsBusinessNeedToFillDinVeHeshbonInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  isDinVeHeshbonNeeded: Scalars['Boolean']['input'];
};

export type IsBusinessNeedToFillDinVeHeshbonOutput = {
  __typename?: 'IsBusinessNeedToFillDinVeHeshbonOutput';
  data: Scalars['Boolean']['output'];
};

export type ItemImportedIncomes = {
  __typename?: 'ItemImportedIncomes';
  description?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceIncludingVat?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  units?: Maybe<Scalars['Float']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
};

export type ListActivitiesForBusinessResponse = {
  __typename?: 'ListActivitiesForBusinessResponse';
  items?: Maybe<Array<BusinessActivity>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessBillingsByBusinessIdInput = {
  billingStatus?: InputMaybe<BusinessBillingStatus>;
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListBusinessBillingsByBusinessIdOutput = {
  __typename?: 'ListBusinessBillingsByBusinessIdOutput';
  items?: Maybe<Array<BusinessBilling>>;
  total: Scalars['Int']['output'];
};

export type ListBusinessDocumentsInput = {
  businessId: Scalars['ID']['input'];
};

export type ListBusinessExpensesResponse = {
  __typename?: 'ListBusinessExpensesResponse';
  items?: Maybe<Array<BusinessExpense>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessFilesInput = {
  businessId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
};

export type ListBusinessIncomesResponse = {
  __typename?: 'ListBusinessIncomesResponse';
  items?: Maybe<Array<BusinessIncome>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessIndustriesResponse = {
  __typename?: 'ListBusinessIndustriesResponse';
  items?: Maybe<Array<BusinessIndustry>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessPendingDocumentsToUpload = {
  __typename?: 'ListBusinessPendingDocumentsToUpload';
  advanceTaxRate: Scalars['Boolean']['output'];
  approvedAt: Scalars['String']['output'];
  bituahLeumiPayment: Scalars['Boolean']['output'];
  businessCertificate: Scalars['Boolean']['output'];
  businessExemption: Scalars['Boolean']['output'];
  businessIndustry: Scalars['Boolean']['output'];
  businessName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
};

export type ListBusinessPendingDocumentsToUploadInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ListBusinessPendingDocumentsToUploadResponse = {
  __typename?: 'ListBusinessPendingDocumentsToUploadResponse';
  items?: Maybe<Array<ListBusinessPendingDocumentsToUpload>>;
  total: Scalars['Int']['output'];
};

export type ListBusinessRecentIncomeItemsResponse = {
  __typename?: 'ListBusinessRecentIncomeItemsResponse';
  items?: Maybe<Array<Maybe<BusinessRecentIncomeItem>>>;
  total: Scalars['Int']['output'];
};

export type ListBusinessReportsResponse = {
  __typename?: 'ListBusinessReportsResponse';
  items?: Maybe<Array<BusinessReport>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessSubscriptionsResponse = {
  __typename?: 'ListBusinessSubscriptionsResponse';
  items?: Maybe<Array<BusinessSubscription>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListBusinessesResponse = {
  __typename?: 'ListBusinessesResponse';
  items?: Maybe<Array<Business>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListCompanySubscriptionPlanInput = {
  cancelled: Scalars['Boolean']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ListCompanySubscriptionPlanOutput = {
  __typename?: 'ListCompanySubscriptionPlanOutput';
  items?: Maybe<Array<CompanySubscriptionPlan>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListDiscountPhoneNumbersResponse = {
  __typename?: 'ListDiscountPhoneNumbersResponse';
  items?: Maybe<Array<DiscountPhoneNumber>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListIncomesForBusinessCustomer = {
  __typename?: 'ListIncomesForBusinessCustomer';
  accountantId?: Maybe<Scalars['ID']['output']>;
  business?: Maybe<Business>;
  businessId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<BusinessCustomer>;
  customerId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Maybe<BusinessMailer>>>;
  endDate?: Maybe<Scalars['String']['output']>;
  english?: Maybe<Scalars['Boolean']['output']>;
  fullPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  incomeDate?: Maybe<Scalars['String']['output']>;
  incomeProvider?: Maybe<BusinessIncomeProvider>;
  incomeStatus?: Maybe<BusinessIncomeStatus>;
  incomeType?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<BusinessIncomeItem>>>;
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<Maybe<BusinessIncomePayment>>>;
  price?: Maybe<Scalars['Float']['output']>;
  priceIncludingVat?: Maybe<Scalars['Float']['output']>;
  reportId?: Maybe<Scalars['ID']['output']>;
  reportType?: Maybe<BusinessReportType>;
  startDate?: Maybe<Scalars['String']['output']>;
  totalDeductedTax?: Maybe<Scalars['Float']['output']>;
  totalDeductedVat?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
  vatType?: Maybe<Scalars['String']['output']>;
};

export type ListIncomesForBusinessCustomerInput = {
  businessId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ListIncomesForBusinessCustomerResponse = {
  __typename?: 'ListIncomesForBusinessCustomerResponse';
  items?: Maybe<Array<Maybe<ListIncomesForBusinessCustomer>>>;
  total: Scalars['Int']['output'];
};

export type ListOpenfrmtFilesInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  generateFileDate: Scalars['String']['input'];
  registrationNumber: Scalars['String']['input'];
};

export type ListProvidersResponse = {
  __typename?: 'ListProvidersResponse';
  items?: Maybe<Array<BusinessProvider>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListStaffs = {
  __typename?: 'ListStaffs';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  isNotified?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<StaffPriority>;
  signatureName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StaffTypes>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ListStaffsByParentIdByParentId = {
  __typename?: 'ListStaffsByParentIdByParentId';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  isNotified?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<StaffPriority>;
  signatureName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StaffTypes>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ListStaffsByParentIdByParentIdResponse = {
  __typename?: 'ListStaffsByParentIdByParentIdResponse';
  items: Array<Maybe<ListStaffsByParentIdByParentId>>;
  total: Scalars['Int']['output'];
};

export type ListStaffsResponse = {
  __typename?: 'ListStaffsResponse';
  items?: Maybe<Array<Maybe<ListStaffs>>>;
  total: Scalars['Int']['output'];
};

export type ListTaxCategoriesResponse = {
  __typename?: 'ListTaxCategoriesResponse';
  items?: Maybe<Array<TaxCategory>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ListUserNotesOutput = {
  __typename?: 'ListUserNotesOutput';
  completed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  handledBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  noteType?: Maybe<NotesTypes>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type ListUserNotesResponse = {
  __typename?: 'ListUserNotesResponse';
  items?: Maybe<Array<ListUserNotesOutput>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum MissingDocument {
  BANK_DOCUMENT = 'BANK_DOCUMENT',
  ID_AND_BANK_DOCUMENT = 'ID_AND_BANK_DOCUMENT',
  ID_DOCUMENT = 'ID_DOCUMENT',
  NONE = 'NONE'
}

export enum MobilePlatformType {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export enum ModelAttributeTypes {
  _NULL = '_null',
  BINARY = 'binary',
  BINARYSET = 'binarySet',
  BOOL = 'bool',
  LIST = 'list',
  MAP = 'map',
  NUMBER = 'number',
  NUMBERSET = 'numberSet',
  STRING = 'string',
  STRINGSET = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelBusinessNotificationConnection = {
  __typename?: 'ModelBusinessNotificationConnection';
  items?: Maybe<Array<Maybe<BusinessNotification>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCreditTypeConnection = {
  __typename?: 'ModelCreditTypeConnection';
  items: Array<Maybe<CreditType>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCreditTypeEnumInput = {
  eq?: InputMaybe<CreditTypeEnum>;
  ne?: InputMaybe<CreditTypeEnum>;
};

export type ModelCreditTypeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCreditTypeFilterInput>>>;
  credits?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelCreditTypeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCreditTypeFilterInput>>>;
  type?: InputMaybe<ModelCreditTypeEnumInput>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
};

export type MoveBusinessExpenseInput = {
  businessId: Scalars['ID']['input'];
  expensesId: Array<Scalars['ID']['input']>;
  newReportId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountantSignTaxAuthorityAndBituahLeumiDocuments?: Maybe<AccountantSignTaxAuthorityAndBituahLeumiDocumentsOutput>;
  accountantSignVatBusinessCreationDocument?: Maybe<AccountantSignVatBusinessCreationDocumentOutput>;
  addDiscountPhoneNumber?: Maybe<AddDiscountPhoneNumberOutput>;
  addNewStaff?: Maybe<AddNewStaffOutput>;
  approveBusiness?: Maybe<ApproveBusinessOutput>;
  approveBusinessExpense?: Maybe<ApproveBusinessExpenseOutput>;
  approveRepresentation?: Maybe<ApproveRepresentationOutput>;
  approveSignaturesOnTaxAuthorityAndBituahLeumiDocuments?: Maybe<ApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsOutput>;
  approveSignaturesOnVatBusinessCreationDocument?: Maybe<ApproveSignaturesOnVatBusinessCreationDocumentOutput>;
  autoSignBO?: Maybe<AutoSignOutput>;
  autoSignMobile?: Maybe<AutoSignOutput>;
  bankToPdf?: Maybe<BankToPdfOutput>;
  businessSignTaxAuthorityAndBituahLeumiDocuments?: Maybe<BusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput>;
  businessSignVatBusinessCreationDocument?: Maybe<BusinessSignVatBusinessCreationDocumentOutput>;
  businessSubscriptionConverter?: Maybe<BusinessSubscriptionConverterOutput>;
  businessTypeConverter?: Maybe<BusinessTypeConverterOutput>;
  businessUploadMissingDocuments?: Maybe<BusinessUploadMissingDocumentsOutput>;
  calculationBusinessReport?: Maybe<CalculationBusinessReportOutput>;
  canBeBlocked?: Maybe<CanBeBlockedOutput>;
  cancelBusiness?: Maybe<CancelBusinessOutput>;
  chargeFailedBusinessBilling?: Maybe<Scalars['Boolean']['output']>;
  chargeFailedBusinessBillingMobile?: Maybe<ChargeFailedBusinessBillingMobileOutput>;
  closeBusinessReport?: Maybe<CloseBusinessReportOutput>;
  createBusinessExpense?: Maybe<CreateBusinessExpenseOutput>;
  createBusinessIncome?: Maybe<CreateBusinessIncomeOutput>;
  createCustomer?: Maybe<CreateCustomerOutput>;
  createProvider?: Maybe<CreateProviderOutput>;
  createTaxCategory?: Maybe<CreateTaxCategoryOutput>;
  createUserNote?: Maybe<CreateUserNoteOutput>;
  declineBusinessExpenseDispute?: Maybe<Scalars['Boolean']['output']>;
  deleteBusinessExpense?: Maybe<Scalars['Boolean']['output']>;
  deleteBusinessFile?: Maybe<DeleteBusinessFileOutput>;
  deleteUserNotificationToken?: Maybe<Scalars['Boolean']['output']>;
  disputeBusinessExpense?: Maybe<DisputeBusinessExpenseOutput>;
  documentTree?: Maybe<DocumentTreeOutput>;
  draftBusinessSignTaxAuthorityAndBituahLeumiDocuments?: Maybe<DraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsOutput>;
  draftBusinessSignVatBusinessCreationDocument?: Maybe<DraftBusinessSignVatBusinessCreationDocumentOutput>;
  editCustomer?: Maybe<EditCustomerOutput>;
  editDinVeHeshbonToDbBO?: Maybe<EditDinVeHeshbonToDbOutput>;
  editDinVeHeshbonToDbMobile?: Maybe<EditDinVeHeshbonToDbOutput>;
  editProvider?: Maybe<EditProviderOutput>;
  editTaxCategory?: Maybe<EditTaxCategoryOutput>;
  fillDinVeHeshbon?: Maybe<FillDinVeHeshbonOutput>;
  generateCardIndex?: Maybe<GenerateCardIndexOutput>;
  generateJonniIncomeReport?: Maybe<File>;
  generateOpenfrmtFiles?: Maybe<GenerateOpenfrmtFileOutput>;
  generateProfitLossDocument?: Maybe<GenerateProfitLossDocumentOutput>;
  getDataFromPdfBO?: Maybe<GetDataFromPdfOutput>;
  getDataFromPdfMobile?: Maybe<GetDataFromPdfOutput>;
  holdBusiness?: Maybe<HoldBusinessOutput>;
  idToPdf?: Maybe<IdToPdfOutput>;
  importBusinessIncome?: Maybe<ImportBusinessIncomeOutput>;
  importDataFromCsvOrExecl?: Maybe<ImportDataFromCsvOrExeclOutput>;
  insertDinVeHeshbonToDbBO?: Maybe<InsertDinVeHeshbonToDbOutput>;
  insertDinVeHeshbonToDbMobile?: Maybe<InsertDinVeHeshbonToDbOutput>;
  isBusinessNeedToFillDinVeHeshbon?: Maybe<IsBusinessNeedToFillDinVeHeshbonOutput>;
  moveBusinessExpense?: Maybe<Scalars['Boolean']['output']>;
  refundBusinessBilling?: Maybe<Scalars['Boolean']['output']>;
  reopenApprovedBusinessExpense?: Maybe<Scalars['Boolean']['output']>;
  reopenBusinessReport?: Maybe<ReopenBusinessReportOutput>;
  responseBusinessApproved?: Maybe<ResponseBusinessApprovedOutput>;
  responseBusinessDeclined?: Maybe<ResponseBusinessDeclinedOutput>;
  restoreBusiness?: Maybe<RestoreBusinessOutput>;
  sendBusinessIncomeToEmail?: Maybe<SendBusinessIncomeToEmailOutput>;
  setUserNotificationToken?: Maybe<Scalars['Boolean']['output']>;
  setWasUserContacted?: Maybe<SetWasUserContactedOutput>;
  signUp?: Maybe<SignUpOutput>;
  submitAuthoritiesIdentification?: Maybe<SubmitAuthoritiesIdentificationOutput>;
  submitBankDetails?: Maybe<SubmitBankDetailsOutput>;
  submitBillingDetails?: Maybe<SubmitBillingDetailsOutput>;
  submitBusinessApprovalStepFive?: Maybe<Scalars['Boolean']['output']>;
  submitBusinessApprovalStepFour?: Maybe<Scalars['Boolean']['output']>;
  submitBusinessApprovalStepOne?: Maybe<Scalars['Boolean']['output']>;
  submitBusinessApprovalStepThree?: Maybe<Scalars['Boolean']['output']>;
  submitBusinessApprovalStepTwo?: Maybe<Scalars['Boolean']['output']>;
  submitBusinessDetails?: Maybe<SubmitBusinessDetailsOutput>;
  submitBusinessEnglishDetails?: Maybe<SubmitBusinessEnglishDetailsOutput>;
  submitBusinessLogo?: Maybe<SubmitBusinessLogoOutput>;
  submitBusinessSetup?: Maybe<SubmitBusinessSetupOutput>;
  submitCompanySubscriptionPlan?: Maybe<Scalars['Boolean']['output']>;
  submitFriendReferral?: Maybe<SubmitFriendReferralOutput>;
  submitGiftDetails?: Maybe<SubmitGiftDetailsOutput>;
  submitIdentificationUpload?: Maybe<SubmitIdentificationUploadOutput>;
  submitIncomeDocumentComments?: Maybe<SubmitIncomeDocumentCommentsOutput>;
  submitNewBusinessDetails?: Maybe<SubmitNewBusinessDetailsOutput>;
  submitReportForBusinessApproval?: Maybe<SubmitReportForBusinessApprovalOutput>;
  taxAuthorityOpenApi?: Maybe<TaxAuthorityOpenApiOutput>;
  updateBusinessAddress?: Maybe<Scalars['Boolean']['output']>;
  updateBusinessAdvanceTaxRateForReport?: Maybe<UpdateBusinessAdvanceTaxRateForReportOutput>;
  updateBusinessAdvances?: Maybe<Scalars['Boolean']['output']>;
  updateBusinessApprovalStatus?: Maybe<UpdateBusinessApprovalStatusOutput>;
  updateBusinessBilling?: Maybe<UpdateBusinessBillingDetailsOutput>;
  updateBusinessBituahLeumiPayment?: Maybe<Scalars['Boolean']['output']>;
  updateBusinessBituahLeumiPaymentForReport?: Maybe<UpdateBusinessBituahLeumiPaymentForReportOutput>;
  updateBusinessCertificate?: Maybe<UpdateBusinessCertificateOutput>;
  updateBusinessDetails?: Maybe<Scalars['Boolean']['output']>;
  updateBusinessExpense?: Maybe<Scalars['Boolean']['output']>;
  updateCompletedInUserNote?: Maybe<UpdateCompletedInUserNoteOutput>;
  updatePaymentsForReports?: Maybe<UpdatePaymentsForReportsOutput>;
  uploadUnsignedTaxAuthorityAndBituahLeumiDocuments?: Maybe<UploadUnsignedTaxAuthorityAndBituahLeumiDocumentsOutput>;
  uploadUnsignedVatBusinessCreationDocument?: Maybe<UploadUnsignedVatBusinessCreationDocumentOutput>;
};


export type MutationAccountantSignTaxAuthorityAndBituahLeumiDocumentsArgs = {
  input?: InputMaybe<AccountantSignTaxAuthorityAndBituahLeumiDocumentsInput>;
};


export type MutationAccountantSignVatBusinessCreationDocumentArgs = {
  input?: InputMaybe<AccountantSignVatBusinessCreationDocumentInput>;
};


export type MutationAddDiscountPhoneNumberArgs = {
  input?: InputMaybe<AddDiscountPhoneNumberInput>;
};


export type MutationAddNewStaffArgs = {
  input?: InputMaybe<AddNewStaffInput>;
};


export type MutationApproveBusinessArgs = {
  input?: InputMaybe<ApproveBusinessInput>;
};


export type MutationApproveBusinessExpenseArgs = {
  input?: InputMaybe<ApproveBusinessExpenseInput>;
};


export type MutationApproveRepresentationArgs = {
  input?: InputMaybe<ApproveRepresentationInput>;
};


export type MutationApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsArgs = {
  input?: InputMaybe<ApproveSignaturesOnTaxAuthorityAndBituahLeumiDocumentsInput>;
};


export type MutationApproveSignaturesOnVatBusinessCreationDocumentArgs = {
  input?: InputMaybe<ApproveSignaturesOnVatBusinessCreationDocumentInput>;
};


export type MutationAutoSignBoArgs = {
  input?: InputMaybe<AutoSignInput>;
};


export type MutationAutoSignMobileArgs = {
  input?: InputMaybe<AutoSignInput>;
};


export type MutationBankToPdfArgs = {
  input?: InputMaybe<BankToPdfInput>;
};


export type MutationBusinessSignTaxAuthorityAndBituahLeumiDocumentsArgs = {
  input?: InputMaybe<BusinessSignTaxAuthorityAndBituahLeumiDocumentsInput>;
};


export type MutationBusinessSignVatBusinessCreationDocumentArgs = {
  input?: InputMaybe<BusinessSignVatBusinessCreationDocumentInput>;
};


export type MutationBusinessSubscriptionConverterArgs = {
  input?: InputMaybe<BusinessSubscriptionConverterInput>;
};


export type MutationBusinessTypeConverterArgs = {
  input?: InputMaybe<BusinessTypeConverterInput>;
};


export type MutationBusinessUploadMissingDocumentsArgs = {
  input?: InputMaybe<BusinessUploadMissingDocumentsInput>;
};


export type MutationCalculationBusinessReportArgs = {
  input?: InputMaybe<CalculationBusinessReportInput>;
};


export type MutationCanBeBlockedArgs = {
  input?: InputMaybe<CanBeBlockedInput>;
};


export type MutationCancelBusinessArgs = {
  input?: InputMaybe<CancelBusinessInput>;
};


export type MutationChargeFailedBusinessBillingArgs = {
  input?: InputMaybe<ChargeFailedBusinessBillingInput>;
};


export type MutationChargeFailedBusinessBillingMobileArgs = {
  input?: InputMaybe<ChargeFailedBusinessBillingMobileInput>;
};


export type MutationCloseBusinessReportArgs = {
  input?: InputMaybe<CloseBusinessReportInput>;
};


export type MutationCreateBusinessExpenseArgs = {
  input?: InputMaybe<CreateBusinessExpenseInput>;
};


export type MutationCreateBusinessIncomeArgs = {
  input?: InputMaybe<CreateBusinessIncomeInput>;
};


export type MutationCreateCustomerArgs = {
  input?: InputMaybe<CreateCustomerInput>;
};


export type MutationCreateProviderArgs = {
  input?: InputMaybe<CreateProviderInput>;
};


export type MutationCreateTaxCategoryArgs = {
  input?: InputMaybe<CreateTaxCategoryInput>;
};


export type MutationCreateUserNoteArgs = {
  input?: InputMaybe<CreateUserNoteInput>;
};


export type MutationDeclineBusinessExpenseDisputeArgs = {
  input?: InputMaybe<DeclineBusinessExpenseDisputeInput>;
};


export type MutationDeleteBusinessExpenseArgs = {
  input?: InputMaybe<DeleteBusinessExpenseInput>;
};


export type MutationDeleteBusinessFileArgs = {
  input?: InputMaybe<DeleteBusinessFileInput>;
};


export type MutationDeleteUserNotificationTokenArgs = {
  input?: InputMaybe<DeleteUserNotificationTokenInput>;
};


export type MutationDisputeBusinessExpenseArgs = {
  input?: InputMaybe<DisputeBusinessExpenseInput>;
};


export type MutationDocumentTreeArgs = {
  input?: InputMaybe<DocumentTreeInput>;
};


export type MutationDraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsArgs = {
  input?: InputMaybe<DraftBusinessSignTaxAuthorityAndBituahLeumiDocumentsInput>;
};


export type MutationDraftBusinessSignVatBusinessCreationDocumentArgs = {
  input?: InputMaybe<DraftBusinessSignVatBusinessCreationDocumentInput>;
};


export type MutationEditCustomerArgs = {
  input?: InputMaybe<EditCustomerInput>;
};


export type MutationEditDinVeHeshbonToDbBoArgs = {
  input?: InputMaybe<EditDinVeHeshbonToDbInput>;
};


export type MutationEditDinVeHeshbonToDbMobileArgs = {
  input?: InputMaybe<EditDinVeHeshbonToDbInput>;
};


export type MutationEditProviderArgs = {
  input?: InputMaybe<EditProviderInput>;
};


export type MutationEditTaxCategoryArgs = {
  input?: InputMaybe<EditTaxCategoryInput>;
};


export type MutationFillDinVeHeshbonArgs = {
  input?: InputMaybe<InsertDinVeHeshbonToDbInput>;
};


export type MutationGenerateCardIndexArgs = {
  input?: InputMaybe<GenerateCardIndexInput>;
};


export type MutationGenerateJonniIncomeReportArgs = {
  input?: InputMaybe<GenerateJonniIncomeReportInput>;
};


export type MutationGenerateOpenfrmtFilesArgs = {
  input?: InputMaybe<GenerateOpenfrmtFileInput>;
};


export type MutationGenerateProfitLossDocumentArgs = {
  input?: InputMaybe<GenerateProfitLossDocumentInput>;
};


export type MutationGetDataFromPdfBoArgs = {
  input?: InputMaybe<GetDataFromPdfInput>;
};


export type MutationGetDataFromPdfMobileArgs = {
  input?: InputMaybe<GetDataFromPdfInput>;
};


export type MutationHoldBusinessArgs = {
  input?: InputMaybe<HoldBusinessInput>;
};


export type MutationIdToPdfArgs = {
  input?: InputMaybe<IdToPdfInput>;
};


export type MutationImportBusinessIncomeArgs = {
  input?: InputMaybe<ImportBusinessIncomeInput>;
};


export type MutationImportDataFromCsvOrExeclArgs = {
  input?: InputMaybe<ImportDataFromCsvOrExeclInput>;
};


export type MutationInsertDinVeHeshbonToDbBoArgs = {
  input?: InputMaybe<InsertDinVeHeshbonToDbInput>;
};


export type MutationInsertDinVeHeshbonToDbMobileArgs = {
  input?: InputMaybe<InsertDinVeHeshbonToDbInput>;
};


export type MutationIsBusinessNeedToFillDinVeHeshbonArgs = {
  input?: InputMaybe<IsBusinessNeedToFillDinVeHeshbonInput>;
};


export type MutationMoveBusinessExpenseArgs = {
  input?: InputMaybe<MoveBusinessExpenseInput>;
};


export type MutationRefundBusinessBillingArgs = {
  input?: InputMaybe<RefundBusinessBillingInput>;
};


export type MutationReopenApprovedBusinessExpenseArgs = {
  input?: InputMaybe<ReopenApprovedBusinessExpenseInput>;
};


export type MutationReopenBusinessReportArgs = {
  input?: InputMaybe<ReopenBusinessReportInput>;
};


export type MutationResponseBusinessApprovedArgs = {
  input?: InputMaybe<ResponseBusinessApprovedInput>;
};


export type MutationResponseBusinessDeclinedArgs = {
  input?: InputMaybe<ResponseBusinessDeclinedInput>;
};


export type MutationRestoreBusinessArgs = {
  input?: InputMaybe<RestoreBusinessInput>;
};


export type MutationSendBusinessIncomeToEmailArgs = {
  input?: InputMaybe<SendBusinessIncomeToEmailInput>;
};


export type MutationSetUserNotificationTokenArgs = {
  input?: InputMaybe<SetUserNotificationTokenInput>;
};


export type MutationSetWasUserContactedArgs = {
  input?: InputMaybe<SetWasUserContactedInput>;
};


export type MutationSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};


export type MutationSubmitAuthoritiesIdentificationArgs = {
  input?: InputMaybe<SubmitAuthoritiesIdentificationInput>;
};


export type MutationSubmitBankDetailsArgs = {
  input?: InputMaybe<SubmitBankDetailsInput>;
};


export type MutationSubmitBillingDetailsArgs = {
  input?: InputMaybe<SubmitBillingDetailsInput>;
};


export type MutationSubmitBusinessApprovalStepFiveArgs = {
  input?: InputMaybe<SubmitBusinessApprovalStepFiveInput>;
};


export type MutationSubmitBusinessApprovalStepFourArgs = {
  input?: InputMaybe<SubmitBusinessApprovalStepFourInput>;
};


export type MutationSubmitBusinessApprovalStepOneArgs = {
  input?: InputMaybe<SubmitBusinessApprovalStepOneInput>;
};


export type MutationSubmitBusinessApprovalStepThreeArgs = {
  input?: InputMaybe<SubmitBusinessApprovalStepThreeInput>;
};


export type MutationSubmitBusinessApprovalStepTwoArgs = {
  input?: InputMaybe<SubmitBusinessApprovalStepTwoInput>;
};


export type MutationSubmitBusinessDetailsArgs = {
  input?: InputMaybe<SubmitBusinessDetailsInput>;
};


export type MutationSubmitBusinessEnglishDetailsArgs = {
  input?: InputMaybe<SubmitBusinessEnglishDetailsInput>;
};


export type MutationSubmitBusinessLogoArgs = {
  input?: InputMaybe<SubmitBusinessLogoInput>;
};


export type MutationSubmitBusinessSetupArgs = {
  input?: InputMaybe<SubmitBusinessSetupInput>;
};


export type MutationSubmitCompanySubscriptionPlanArgs = {
  input?: InputMaybe<SubmitCompanySubscriptionPlanInput>;
};


export type MutationSubmitFriendReferralArgs = {
  input?: InputMaybe<SubmitFriendReferralInput>;
};


export type MutationSubmitGiftDetailsArgs = {
  input?: InputMaybe<SubmitGiftDetailsInput>;
};


export type MutationSubmitIdentificationUploadArgs = {
  input?: InputMaybe<SubmitIdentificationUploadInput>;
};


export type MutationSubmitIncomeDocumentCommentsArgs = {
  input?: InputMaybe<SubmitIncomeDocumentCommentsInput>;
};


export type MutationSubmitNewBusinessDetailsArgs = {
  input?: InputMaybe<SubmitNewBusinessDetailsInput>;
};


export type MutationSubmitReportForBusinessApprovalArgs = {
  input?: InputMaybe<SubmitReportForBusinessApprovalInput>;
};


export type MutationTaxAuthorityOpenApiArgs = {
  input?: InputMaybe<TaxAuthorityOpenApiInput>;
};


export type MutationUpdateBusinessAddressArgs = {
  input?: InputMaybe<UpdateBusinessAddressInput>;
};


export type MutationUpdateBusinessAdvanceTaxRateForReportArgs = {
  input?: InputMaybe<UpdateBusinessAdvanceTaxRateForReportInput>;
};


export type MutationUpdateBusinessAdvancesArgs = {
  input?: InputMaybe<UpdateBusinessAdvancesInput>;
};


export type MutationUpdateBusinessApprovalStatusArgs = {
  input?: InputMaybe<UpdateBusinessApprovalStatusInput>;
};


export type MutationUpdateBusinessBillingArgs = {
  input?: InputMaybe<UpdateBusinessBillingDetailsInput>;
};


export type MutationUpdateBusinessBituahLeumiPaymentArgs = {
  input?: InputMaybe<UpdateBusinessBituahLeumiPaymentInput>;
};


export type MutationUpdateBusinessBituahLeumiPaymentForReportArgs = {
  input?: InputMaybe<UpdateBusinessBituahLeumiPaymentForReportInput>;
};


export type MutationUpdateBusinessCertificateArgs = {
  input?: InputMaybe<UpdateBusinessCertificateInput>;
};


export type MutationUpdateBusinessDetailsArgs = {
  input?: InputMaybe<UpdateBusinessDetailsInput>;
};


export type MutationUpdateBusinessExpenseArgs = {
  input?: InputMaybe<UpdateBusinessExpenseInput>;
};


export type MutationUpdateCompletedInUserNoteArgs = {
  input?: InputMaybe<UpdateCompletedInUserNoteInput>;
};


export type MutationUpdatePaymentsForReportsArgs = {
  input?: InputMaybe<UpdatePaymentsForReportsInput>;
};


export type MutationUploadUnsignedTaxAuthorityAndBituahLeumiDocumentsArgs = {
  input?: InputMaybe<UploadUnsignedTaxAuthorityAndBituahLeumiDocumentsInput>;
};


export type MutationUploadUnsignedVatBusinessCreationDocumentArgs = {
  input?: InputMaybe<UploadUnsignedVatBusinessCreationDocumentInput>;
};

export type NextIncomeInvoiceNumber = {
  __typename?: 'NextIncomeInvoiceNumber';
  invoiceNumber: Scalars['Int']['output'];
};

export enum NotesTypes {
  BUSINESS = 'BUSINESS',
  CRM = 'CRM'
}

export type NotificationValues = {
  __typename?: 'NotificationValues';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type NotificationValuesInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum OccupationOptions {
  EMPLOYEE = 'EMPLOYEE',
  HIGH_SCHOOL_STUDENT = 'HIGH_SCHOOL_STUDENT',
  HOUSE_KEEPING = 'HOUSE_KEEPING',
  INCOME_NOT_FROM_WORK = 'INCOME_NOT_FROM_WORK',
  KIBBUTZ_MEMBER = 'KIBBUTZ_MEMBER',
  OTHER = 'OTHER',
  OVERSEAS = 'OVERSEAS',
  PENSION = 'PENSION',
  PRISONER = 'PRISONER',
  PROFESSIONAL_TRAINING = 'PROFESSIONAL_TRAINING',
  SHEROT_LEUMI = 'SHEROT_LEUMI',
  SOLDIER_DUTY = 'SOLDIER_DUTY',
  SOLDIER_PERMANENT = 'SOLDIER_PERMANENT',
  STUDENT = 'STUDENT',
  UNEMPLOYED = 'UNEMPLOYED',
  UNPAYED_VACATION = 'UNPAYED_VACATION',
  YESHIVA_STUDENT = 'YESHIVA_STUDENT'
}

export enum OnboardingStatus {
  AUTHORITIES_IDENTIFICATION = 'AUTHORITIES_IDENTIFICATION',
  BILLING_DETAILS = 'BILLING_DETAILS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  BUSINESS_SETUP = 'BUSINESS_SETUP',
  DONE = 'DONE',
  GIFT_DETAILS = 'GIFT_DETAILS',
  IDENTIFICATION_UPLOAD = 'IDENTIFICATION_UPLOAD',
  NEW_BUSINESS_DETAILS = 'NEW_BUSINESS_DETAILS'
}

export type PaymentImportedIncomes = {
  __typename?: 'PaymentImportedIncomes';
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type PdfDataOutput = {
  __typename?: 'PdfDataOutput';
  base64Pdf: Scalars['String']['output'];
  pdfType: Scalars['String']['output'];
};

export enum PlanType {
  FAMILY = 'FAMILY',
  INDEPENDENCE_DAY = 'INDEPENDENCE_DAY',
  ONEMONTHS33 = 'ONEMONTHS33',
  ONEMONTHS50 = 'ONEMONTHS50',
  ONEMONTHS100 = 'ONEMONTHS100',
  ONEYEAR10 = 'ONEYEAR10',
  ONEYEAR20 = 'ONEYEAR20',
  ONEYEAR30 = 'ONEYEAR30',
  PILOT = 'PILOT',
  REGULAR = 'REGULAR',
  THREEMONTHS33 = 'THREEMONTHS33',
  THREEMONTHS50 = 'THREEMONTHS50',
  THREEMONTHS100 = 'THREEMONTHS100',
  TWOMONTHS33 = 'TWOMONTHS33',
  TWOMONTHS50 = 'TWOMONTHS50',
  TWOMONTHS100 = 'TWOMONTHS100'
}

export type Query = {
  __typename?: 'Query';
  businessBillingsChartData?: Maybe<BusinessBillingsChartDataOutput>;
  businessBillingsDataByDate?: Maybe<BusinessBillingsDataByDateOutput>;
  businessBillingsTableByDate?: Maybe<BusinessBillingsTableByDateOutput>;
  getAccountant?: Maybe<Accountant>;
  getAccountantSignature?: Maybe<File>;
  getAccountantSignatureUploadUrl?: Maybe<File>;
  getBusiness?: Maybe<Business>;
  getBusinessExpense?: Maybe<BusinessExpense>;
  getBusinessFile?: Maybe<File>;
  getBusinessFileUploadUrl?: Maybe<File>;
  getBusinessIncome?: Maybe<BusinessIncome>;
  getBusinessIncomesCeilingForBusiness?: Maybe<GetBusinessIncomesCeilingForBusinessOutput>;
  getBusinessNotification?: Maybe<BusinessNotification>;
  getBusinessPendingPaymentsData?: Maybe<GetBusinessPendingPaymentsDataOutput>;
  getBusinessProvider?: Maybe<BusinessProvider>;
  getBusinessReport?: Maybe<BusinessReport>;
  getBusinessSetup?: Maybe<BusinessSetup>;
  getBusinessStatusCounters?: Maybe<BusinessStatusCounters>;
  getCountBusinessFailedBillings?: Maybe<GetCountBusinessFailedBillingsResponse>;
  getCountOpenfrmtFiles?: Maybe<GetCountOpenfrmtFiles>;
  getCurrencyRate?: Maybe<CurrencyRate>;
  getDinVeHeshbonDataBO?: Maybe<GetDinVeHeshbonDataOutput>;
  getDinVeHeshbonDataMobile?: Maybe<GetDinVeHeshbonDataOutput>;
  getHomeTabsCounters?: Maybe<HomeTabsCounters>;
  getListYearsForBusinessBo?: Maybe<GetListYearsForBusinessOutput>;
  getListYearsForBusinessMobile?: Maybe<GetListYearsForBusinessOutput>;
  getNextBusinessSubscriptionPayment?: Maybe<GetNextBusinessSubscriptionPaymentResponse>;
  getNextIncomeInvoiceNumber?: Maybe<NextIncomeInvoiceNumber>;
  getPreviousIncomeDate?: Maybe<GetPreviousIncomeOutput>;
  getStatisticsForBusiness?: Maybe<BusinessStatistics>;
  getSubscriptionPlans?: Maybe<Array<Maybe<BusinessSubscriptionPlan>>>;
  getTaxCategory?: Maybe<TaxCategory>;
  getUser?: Maybe<User>;
  listActivitiesForBusiness?: Maybe<ListActivitiesForBusinessResponse>;
  listBusinessBillingsByBusinessId?: Maybe<ListBusinessBillingsByBusinessIdOutput>;
  listBusinessDocuments?: Maybe<Array<BusinessDocument>>;
  listBusinessExpenses?: Maybe<ListBusinessExpensesResponse>;
  listBusinessExpensesForBusiness?: Maybe<ListBusinessExpensesResponse>;
  listBusinessFiles?: Maybe<Array<BusinessFile>>;
  listBusinessIncomes?: Maybe<ListBusinessIncomesResponse>;
  listBusinessIncomesDocuments?: Maybe<ListBusinessIncomesResponse>;
  listBusinessIndustries?: Maybe<ListBusinessIndustriesResponse>;
  listBusinessPendingDocumentsToUpload?: Maybe<ListBusinessPendingDocumentsToUploadResponse>;
  listBusinessRecentIncomeItems?: Maybe<ListBusinessRecentIncomeItemsResponse>;
  listBusinessReports?: Maybe<ListBusinessReportsResponse>;
  listBusinessReportsForBusiness?: Maybe<ListBusinessReportsResponse>;
  listBusinessSubscriptions?: Maybe<ListBusinessSubscriptionsResponse>;
  listBusinesses?: Maybe<ListBusinessesResponse>;
  listCompanySubscriptionPlan?: Maybe<ListCompanySubscriptionPlanOutput>;
  listDiscountPhoneNumbers?: Maybe<ListDiscountPhoneNumbersResponse>;
  listIncomesForBusinessCustomer?: Maybe<ListIncomesForBusinessCustomerResponse>;
  listOpenfrmtFiles?: Maybe<Array<BusinessFile>>;
  listPopularProviders?: Maybe<ListPopularProvidersResponse>;
  listProviders?: Maybe<ListProvidersResponse>;
  listStaffs?: Maybe<ListStaffsResponse>;
  listStaffsByParentId?: Maybe<ListStaffsByParentIdByParentIdResponse>;
  listTaxCategories?: Maybe<ListTaxCategoriesResponse>;
  listUserNotes?: Maybe<ListUserNotesResponse>;
};


export type QueryBusinessBillingsChartDataArgs = {
  input?: InputMaybe<BusinessBillingsChartDataInput>;
};


export type QueryBusinessBillingsDataByDateArgs = {
  input?: InputMaybe<BusinessBillingsDataByDateInput>;
};


export type QueryBusinessBillingsTableByDateArgs = {
  input?: InputMaybe<BusinessBillingsTableByDateInput>;
};


export type QueryGetAccountantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAccountantSignatureArgs = {
  input?: InputMaybe<GetAccountantSignatureInput>;
};


export type QueryGetAccountantSignatureUploadUrlArgs = {
  input?: InputMaybe<GetAccountantSignatureUploadUrlInput>;
};


export type QueryGetBusinessArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBusinessExpenseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBusinessFileArgs = {
  input?: InputMaybe<GetBusinessFileInput>;
};


export type QueryGetBusinessFileUploadUrlArgs = {
  input?: InputMaybe<GetBusinessFileUploadUrlInput>;
};


export type QueryGetBusinessIncomeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBusinessIncomesCeilingForBusinessArgs = {
  input?: InputMaybe<GetBusinessIncomesCeilingForBusinessInput>;
};


export type QueryGetBusinessNotificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetBusinessPendingPaymentsDataArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetBusinessProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBusinessReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBusinessSetupArgs = {
  mobile?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCountBusinessFailedBillingsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetCountOpenfrmtFilesArgs = {
  businessId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryGetCurrencyRateArgs = {
  input?: InputMaybe<GetCurrencyRateInput>;
};


export type QueryGetDinVeHeshbonDataBoArgs = {
  input?: InputMaybe<GetDinVeHeshbonDataInput>;
};


export type QueryGetDinVeHeshbonDataMobileArgs = {
  input?: InputMaybe<GetDinVeHeshbonDataInput>;
};


export type QueryGetListYearsForBusinessBoArgs = {
  input?: InputMaybe<GetListYearsForBusinessInput>;
};


export type QueryGetListYearsForBusinessMobileArgs = {
  input?: InputMaybe<GetListYearsForBusinessInput>;
};


export type QueryGetNextBusinessSubscriptionPaymentArgs = {
  input?: InputMaybe<GetNextBusinessSubscriptionPaymentInput>;
};


export type QueryGetNextIncomeInvoiceNumberArgs = {
  input?: InputMaybe<GetNextIncomeInvoiceNumberInput>;
};


export type QueryGetPreviousIncomeDateArgs = {
  input?: InputMaybe<GetPreviousIncomeInput>;
};


export type QueryGetStatisticsForBusinessArgs = {
  input?: InputMaybe<GetStatisticsForBusinessInput>;
};


export type QueryGetTaxCategoryArgs = {
  irsCode: Scalars['Int']['input'];
};


export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryListActivitiesForBusinessArgs = {
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type: ActivitiesTabs;
};


export type QueryListBusinessBillingsByBusinessIdArgs = {
  billingStatus?: InputMaybe<BusinessBillingStatus>;
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListBusinessDocumentsArgs = {
  input?: InputMaybe<ListBusinessDocumentsInput>;
};


export type QueryListBusinessExpensesArgs = {
  expenseStatus?: InputMaybe<Array<InputMaybe<BusinessExpenseStatus>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessExpensesForBusinessArgs = {
  businessId: Scalars['ID']['input'];
  expenseStatus?: InputMaybe<Array<BusinessExpenseStatus>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessFilesArgs = {
  input?: InputMaybe<ListBusinessFilesInput>;
};


export type QueryListBusinessIncomesArgs = {
  businessType?: InputMaybe<BusinessType>;
  incomeStatus?: InputMaybe<BusinessIncomeStatus>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessIncomesDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessIndustriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessPendingDocumentsToUploadArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryListBusinessRecentIncomeItemsArgs = {
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  reportStatus?: InputMaybe<BusinessReportStatus>;
};


export type QueryListBusinessReportsForBusinessArgs = {
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListBusinessSubscriptionsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryListBusinessesArgs = {
  businessStatus?: InputMaybe<BusinessStatus>;
  businessType?: InputMaybe<BusinessType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onboardingStatus?: InputMaybe<Array<InputMaybe<OnboardingStatus>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCompanySubscriptionPlanArgs = {
  input?: InputMaybe<ListCompanySubscriptionPlanInput>;
};


export type QueryListDiscountPhoneNumbersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListIncomesForBusinessCustomerArgs = {
  businessId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListOpenfrmtFilesArgs = {
  input?: InputMaybe<ListOpenfrmtFilesInput>;
};


export type QueryListPopularProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListStaffsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type: Array<StaffTypes>;
};


export type QueryListStaffsByParentIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentId: Scalars['ID']['input'];
};


export type QueryListTaxCategoriesArgs = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUserNotesArgs = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  noteType: NotesTypes;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type ReasonImportedIncomes = {
  __typename?: 'ReasonImportedIncomes';
  message?: Maybe<Scalars['String']['output']>;
};

export type RefundBusinessBillingInput = {
  billingId: Scalars['ID']['input'];
};

export type RefundExpenseInput = {
  expenseId: Scalars['ID']['input'];
};

export type RemoveCreditsInput = {
  creditsId: Scalars['ID']['input'];
};

export type RemoveCreditsOutput = {
  __typename?: 'RemoveCreditsOutput';
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ReopenApprovedBusinessExpenseInput = {
  businessId: Scalars['ID']['input'];
  expenseId: Scalars['ID']['input'];
};

export type ReopenBusinessReportInput = {
  reportId: Scalars['ID']['input'];
};

export type ReopenBusinessReportOutput = {
  __typename?: 'ReopenBusinessReportOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reportStatus: BusinessReportStatus;
};

export type ResponseBusinessApprovedInput = {
  reportId: Scalars['ID']['input'];
};

export type ResponseBusinessApprovedOutput = {
  __typename?: 'ResponseBusinessApprovedOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reportStatus: BusinessReportStatus;
};

export type ResponseBusinessDeclinedInput = {
  reportId: Scalars['ID']['input'];
};

export type ResponseBusinessDeclinedOutput = {
  __typename?: 'ResponseBusinessDeclinedOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reportStatus: BusinessReportStatus;
};

export enum RestoreBusinessError {
  INVALID_BUSINESS_STATUS = 'INVALID_BUSINESS_STATUS'
}

export type RestoreBusinessInput = {
  businessId: Scalars['ID']['input'];
};

export type RestoreBusinessOutput = {
  __typename?: 'RestoreBusinessOutput';
  error?: Maybe<CancelBusinessError>;
  success: Scalars['Boolean']['output'];
};

export type SelfEmployedStartObject = {
  averageIncomeNis?: InputMaybe<Scalars['Int']['input']>;
  averageWorkingHours?: InputMaybe<AverageWorkingHoursOptions>;
  dateOfStarting?: InputMaybe<Scalars['String']['input']>;
};

export type SelfEmployedStartObjectX = {
  __typename?: 'SelfEmployedStartObjectX';
  averageIncomeNis?: Maybe<Scalars['Int']['output']>;
  averageWorkingHours?: Maybe<AverageWorkingHoursOptions>;
  dateOfStarting?: Maybe<Scalars['String']['output']>;
};

export type SendBusinessIncomeToEmailInput = {
  email: Scalars['String']['input'];
  incomeId: Scalars['String']['input'];
  isEnglish: Scalars['Boolean']['input'];
};

export type SendBusinessIncomeToEmailOutput = {
  __typename?: 'SendBusinessIncomeToEmailOutput';
  attachments: Scalars['String']['output'];
  businessId: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  emailStatus: EmailStatus;
  id: Scalars['ID']['output'];
  recipient: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type SetUserNotificationTokenInput = {
  fcmToken: Scalars['String']['input'];
  mobilePlatform: MobilePlatformType;
};

export type SetWasUserContactedInput = {
  userId: Scalars['ID']['input'];
};

export type SetWasUserContactedOutput = {
  __typename?: 'SetWasUserContactedOutput';
  data: Scalars['Boolean']['output'];
};

export type SignUpInput = {
  identityId: Scalars['String']['input'];
};

export type SignUpOutput = {
  __typename?: 'SignUpOutput';
  id: Scalars['ID']['output'];
  mobile: Scalars['String']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SingleOccupationOptions = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  isCurrentlyWorkingInThisJob?: InputMaybe<Scalars['Boolean']['input']>;
  occupation?: InputMaybe<OccupationOptions>;
  upToDate?: InputMaybe<Scalars['String']['input']>;
};

export type SingleOccupationOptionsX = {
  __typename?: 'SingleOccupationOptionsX';
  fromDate?: Maybe<Scalars['String']['output']>;
  isCurrentlyWorkingInThisJob?: Maybe<Scalars['Boolean']['output']>;
  occupation?: Maybe<OccupationOptions>;
  upToDate?: Maybe<Scalars['String']['output']>;
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SourceOfIncomes = {
  incomeNis?: InputMaybe<Scalars['Int']['input']>;
  incomeSource?: InputMaybe<Scalars['String']['input']>;
};

export type SourceOfIncomesX = {
  __typename?: 'SourceOfIncomesX';
  incomeNis?: Maybe<Scalars['Int']['output']>;
  incomeSource?: Maybe<Scalars['String']['output']>;
};

export enum StaffPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export enum StaffTypes {
  ACCOUNTANT = 'ACCOUNTANT',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUB_ACCOUNTANT = 'SUB_ACCOUNTANT',
  SUB_ADMINISTRATOR = 'SUB_ADMINISTRATOR'
}

export type SubmitAuthoritiesIdentificationInput = {
  birthDate: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  type: IdentificationType;
  value: Scalars['String']['input'];
};

export type SubmitAuthoritiesIdentificationOutput = {
  __typename?: 'SubmitAuthoritiesIdentificationOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitBankDetailsInput = {
  bankAccount: Scalars['Int']['input'];
  bankBranch: Scalars['Int']['input'];
  bankName: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  name: Translations;
};

export type SubmitBankDetailsOutput = {
  __typename?: 'SubmitBankDetailsOutput';
  data: Scalars['Boolean']['output'];
};

export type SubmitBillingDetailsInput = {
  businessId: Scalars['String']['input'];
  buyerCardExpiration: Scalars['String']['input'];
  buyerCardMask: Scalars['String']['input'];
  buyerKey: Scalars['String']['input'];
};

export type SubmitBillingDetailsOutput = {
  __typename?: 'SubmitBillingDetailsOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
  status: BusinessStatus;
};

export type SubmitBusinessApprovalStepFiveInput = {
  businessId: Scalars['ID']['input'];
};

export type SubmitBusinessApprovalStepFourInput = {
  businessId: Scalars['ID']['input'];
};

export type SubmitBusinessApprovalStepOneInput = {
  accountantRepresentation: BusinessAccountantRepresentationType;
  businessId: Scalars['ID']['input'];
  businessIndustryId: Scalars['String']['input'];
  isNewBusiness: Scalars['Boolean']['input'];
  representationRequestNumber: Scalars['String']['input'];
};

export type SubmitBusinessApprovalStepThreeInput = {
  businessId: Scalars['ID']['input'];
  signature: Scalars['String']['input'];
};

export type SubmitBusinessApprovalStepTwoInput = {
  businessId: Scalars['ID']['input'];
  signature: Scalars['String']['input'];
};

export type SubmitBusinessDetailsInput = {
  businessName: Scalars['String']['input'];
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  idNumber: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  type: BusinessType;
};

export type SubmitBusinessDetailsOutput = {
  __typename?: 'SubmitBusinessDetailsOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitBusinessEnglishDetailsInput = {
  businessId: Scalars['ID']['input'];
  businessNameEn: Scalars['String']['input'];
};

export type SubmitBusinessEnglishDetailsOutput = {
  __typename?: 'SubmitBusinessEnglishDetailsOutput';
  data: Scalars['Boolean']['output'];
};

export type SubmitBusinessLogoInput = {
  base64BusinessLogo?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
};

export type SubmitBusinessLogoOutput = {
  __typename?: 'SubmitBusinessLogoOutput';
  data: Scalars['Boolean']['output'];
};

export type SubmitBusinessSetupInput = {
  businessType: BusinessSetupBusinessType;
  hasAccountant: Scalars['Boolean']['input'];
  hasBusiness: Scalars['Boolean']['input'];
  hasEmployees: Scalars['Boolean']['input'];
};

export type SubmitBusinessSetupOutput = {
  __typename?: 'SubmitBusinessSetupOutput';
  id: Scalars['ID']['output'];
  mobile: Scalars['String']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitCompanySubscriptionPlanInput = {
  companyName: Scalars['String']['input'];
  subscriptionPlanTypeDiscount?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanTypeFree?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitContactUsFormInput = {
  mail: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitFriendReferralInput = {
  mobile: Scalars['String']['input'];
  referralId: Scalars['String']['input'];
};

export type SubmitFriendReferralOutput = {
  __typename?: 'SubmitFriendReferralOutput';
  mobile: Scalars['String']['output'];
  planType: PlanType;
  planTypeFree?: Maybe<PlanType>;
};

export type SubmitGiftDetailsInput = {
  businessId: Scalars['ID']['input'];
};

export type SubmitGiftDetailsOutput = {
  __typename?: 'SubmitGiftDetailsOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitIdentificationUploadInput = {
  bankDocumentType: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
};

export type SubmitIdentificationUploadOutput = {
  __typename?: 'SubmitIdentificationUploadOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitIncomeDocumentCommentsInput = {
  businessId: Scalars['ID']['input'];
  footer?: InputMaybe<Translations>;
  main?: InputMaybe<Translations>;
};

export type SubmitIncomeDocumentCommentsOutput = {
  __typename?: 'SubmitIncomeDocumentCommentsOutput';
  data: Scalars['Boolean']['output'];
};

export type SubmitNewBusinessDetailsInput = {
  businessId: Scalars['ID']['input'];
  businessIndustryId: Scalars['String']['input'];
  businessSecondaryIndustryId?: InputMaybe<Scalars['String']['input']>;
  expectedMonthlyIncome: Scalars['Float']['input'];
};

export type SubmitNewBusinessDetailsOutput = {
  __typename?: 'SubmitNewBusinessDetailsOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onboardingStatus: OnboardingStatus;
};

export type SubmitReportForBusinessApprovalInput = {
  businessId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type SubmitReportForBusinessApprovalOutput = {
  __typename?: 'SubmitReportForBusinessApprovalOutput';
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reportStatus: BusinessReportStatus;
};

export type SyncContactsContact = {
  emailAddresses: Array<SyncContactsEmailAddresses>;
  names: Array<SyncContactsNames>;
  organizations: Array<SyncContactsOrganizations>;
  phoneNumbers: Array<SyncContactsPhoneNumbers>;
};

export type SyncContactsContactGroup = {
  name: Scalars['String']['input'];
};

export type SyncContactsDevelop = {
  contactGroupName?: InputMaybe<Scalars['String']['input']>;
  subjectIn: Scalars['String']['input'];
  subjectOut?: InputMaybe<Scalars['String']['input']>;
};

export type SyncContactsEmailAddresses = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SyncContactsInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Array<SyncContactsPeopleContact>>;
  develop?: InputMaybe<SyncContactsDevelop>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SyncContactsNames = {
  displayName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
};

export type SyncContactsOrganizations = {
  name: Scalars['String']['input'];
};

export type SyncContactsPeopleContact = {
  contact: SyncContactsContact;
  contactGroups?: InputMaybe<Array<SyncContactsContactGroup>>;
};

export type SyncContactsPhoneNumbers = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TaxAuthorityOpenApiInput = {
  businessId: Scalars['String']['input'];
  code: Scalars['String']['input'];
};

export type TaxAuthorityOpenApiOutput = {
  __typename?: 'TaxAuthorityOpenApiOutput';
  data: Scalars['String']['output'];
};

export type TaxCategory = {
  __typename?: 'TaxCategory';
  categoryName: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  irsCode: Scalars['Int']['output'];
  profitLossCategory: TaxCategoryProfitLossCategory;
  taxDeductionPercentage: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  vatDeductionPercentage: Scalars['Float']['output'];
  vatType: TaxCategoryVatType;
};

export enum TaxCategoryProfitLossCategory {
  ADMINISTRATIVE_AND_GENERAL_EXPENSES = 'ADMINISTRATIVE_AND_GENERAL_EXPENSES',
  OTHER = 'OTHER',
  SELLING_COSTS = 'SELLING_COSTS'
}

export enum TaxCategoryVatType {
  ASSETS = 'ASSETS',
  NONE = 'NONE',
  OTHER = 'OTHER'
}

export type Translations = {
  ENGLISH?: InputMaybe<Scalars['String']['input']>;
  HEBREW?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBusinessAddressInput = {
  businessId: Scalars['ID']['input'];
  city: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type UpdateBusinessAdvanceTaxRateForReportInput = {
  advanceTaxRate: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type UpdateBusinessAdvanceTaxRateForReportOutput = {
  __typename?: 'UpdateBusinessAdvanceTaxRateForReportOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdateBusinessAdvancesInput = {
  businessId: Scalars['ID']['input'];
  updatedAdvances: Scalars['Float']['input'];
};

export type UpdateBusinessApprovalStatusInput = {
  approvalStatus: BusinessApprovalStatus;
  businessId: Scalars['ID']['input'];
};

export type UpdateBusinessApprovalStatusOutput = {
  __typename?: 'UpdateBusinessApprovalStatusOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdateBusinessBillingDetailsInput = {
  businessId: Scalars['String']['input'];
  buyerCardExpiration: Scalars['String']['input'];
  buyerCardMask: Scalars['String']['input'];
  buyerKey: Scalars['String']['input'];
};

export type UpdateBusinessBillingDetailsOutput = {
  __typename?: 'UpdateBusinessBillingDetailsOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdateBusinessBituahLeumiPaymentForReportInput = {
  bituahLeumiPaymentFirstPayment: Scalars['Float']['input'];
  bituahLeumiPaymentSecondPayment: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type UpdateBusinessBituahLeumiPaymentForReportOutput = {
  __typename?: 'UpdateBusinessBituahLeumiPaymentForReportOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdateBusinessBituahLeumiPaymentInput = {
  bituahLeumiPayment: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
};

export type UpdateBusinessCertificateInput = {
  businessId: Scalars['ID']['input'];
};

export type UpdateBusinessCertificateOutput = {
  __typename?: 'UpdateBusinessCertificateOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdateBusinessDetailsInput = {
  businessId: Scalars['ID']['input'];
  businessIndustryId?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessSecondaryIndustryId?: InputMaybe<Scalars['String']['input']>;
  isEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  isImportDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  partnerRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  paymentIdNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBusinessExpenseInput = {
  businessId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  date: Scalars['String']['input'];
  expenseNumber?: InputMaybe<Scalars['String']['input']>;
  fullPrice: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  items: Array<BusinessExpenseItemInput>;
  price: Scalars['Float']['input'];
  priceIncludingVat: Scalars['Float']['input'];
  providerId?: InputMaybe<Scalars['ID']['input']>;
  taxDeduction: Scalars['Float']['input'];
  totalDeductedTax: Scalars['Float']['input'];
  totalDeductedVat: Scalars['Float']['input'];
  type: BusinessExpenseType;
  vat: Scalars['Float']['input'];
  vatType: BusinessExpenseVatType;
};

export type UpdateCompletedInUserNoteInput = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateCompletedInUserNoteOutput = {
  __typename?: 'UpdateCompletedInUserNoteOutput';
  data: Scalars['Boolean']['output'];
};

export type UpdatePaymentsForReportsInput = {
  advanceTaxRate?: InputMaybe<Scalars['Float']['input']>;
  bituahLeumiPaymentFirstPayment?: InputMaybe<Scalars['Float']['input']>;
  bituahLeumiPaymentSecondPayment?: InputMaybe<Scalars['Float']['input']>;
  businessId: Scalars['ID']['input'];
  reportsId: Array<Scalars['ID']['input']>;
};

export type UpdatePaymentsForReportsOutput = {
  __typename?: 'UpdatePaymentsForReportsOutput';
  data: Scalars['Boolean']['output'];
};

export type UploadUnsignedTaxAuthorityAndBituahLeumiDocumentsInput = {
  accountantRepresentation: Scalars['String']['input'];
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
  businessIndustryId: Scalars['String']['input'];
  isNewBusiness: Scalars['Boolean']['input'];
  missingDocuments: Scalars['String']['input'];
  representationRequestNumber: Scalars['String']['input'];
};

export type UploadUnsignedTaxAuthorityAndBituahLeumiDocumentsOutput = {
  __typename?: 'UploadUnsignedTaxAuthorityAndBituahLeumiDocumentsOutput';
  data: Scalars['Boolean']['output'];
};

export type UploadUnsignedVatBusinessCreationDocumentInput = {
  businessApprovalStatus: BusinessApprovalStatus;
  businessId: Scalars['String']['input'];
};

export type UploadUnsignedVatBusinessCreationDocumentOutput = {
  __typename?: 'UploadUnsignedVatBusinessCreationDocumentOutput';
  data: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  bankDocumentType?: Maybe<BankDocumentType>;
  birthDate?: Maybe<Scalars['String']['output']>;
  businesses?: Maybe<Array<Business>>;
  city?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  credits?: Maybe<Array<Credit>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idNumber?: Maybe<Scalars['String']['output']>;
  identificationType?: Maybe<IdentificationType>;
  identificationValue?: Maybe<Scalars['String']['output']>;
  identityId: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['String']['output'];
  onboardingStatus: OnboardingStatus;
  streetAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  wasContacted?: Maybe<Scalars['Boolean']['output']>;
};

export type BankToPdfInput = {
  businessId: Scalars['ID']['input'];
};

export type BankToPdfOutput = {
  __typename?: 'bankToPdfOutput';
  document: Scalars['String']['output'];
};

export type IdToPdfInput = {
  businessId: Scalars['ID']['input'];
};

export type IdToPdfOutput = {
  __typename?: 'idToPdfOutput';
  document: Scalars['String']['output'];
};

export type ListPopularProvidersResponse = {
  __typename?: 'listPopularProvidersResponse';
  items?: Maybe<Array<BusinessProvider>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UpdateTaxDeductionDataInput = {
  calledFrom?: InputMaybe<Scalars['String']['input']>;
};
