import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import doneGreen from 'assets/doneGreen.svg';

export const WrapperSteps = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: theme?.spacing(22.75),
  margin: theme?.spacing(0, 3.5),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme?.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledStep = styled('div')<{ nextStep?: boolean }>(({ nextStep }) => ({
  display: 'flex',
  fontSize: '16px',
  color: '#fff',
  opacity: nextStep ? 1 : 0.5,
  zIndex: 1,
}));

export const StyledNumberStep = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  zIndex: 2,
  fontWeight: 'bold',
  fontSize: '16px',
  width: '30px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#4357f2',
  position: 'static',
  marginTop: theme?.spacing(-1),
}));

export const StyledStepsText = styled('div')({
  minWidth: '140px',
  marginInlineStart: '23px',
});

export const StyledStepTitle = styled('div')({
  marginBottom: '30px',
  fontSize: '16px',
  fontWeight: 'bold',
});

export const DoneGreen = styled(doneGreen)({
  position: 'relative',
});
